export interface hostPost {
  description: string;
  datetime: number;
  attendees: number;
  school: string;
  imageUrl: string;
  tags: string[];
  createdAt: string;
  price: number;
  completed: boolean;
  id: string;
}
export interface submission {
  text: string;
  score: number;
  judges: number;
  rank: number;
}

export const SUBMISSION: submission = {
  text: "Diam justo id eu mauris consectetur. Elit vitae non faucibus vitae enim proin maecenas. Id ac vitae tincidunt dapibus ac posuere felis nunc. Non tristique feugiat ornare vel integer. Orci ultrices fames pulvinar blandit dignissim nunc ac nulla mauris. Nulla interdum condimentum nunc porttitor etiam ut iaculis. Convallis odio in amet sed ultrices. Non pellentesque mattis suspendisse tempus facilisi pellentesque proin sem. In proin malesuada faucibus eros interdum. Nec ac iaculis viverra scelerisque eros maecenas mi. Ullamcorper turpis egestas varius pellentesque convallis commodo. Nulla urna id a aliquam scelerisque porttitor. Leo pretium vitae commodo tincidunt ut commodo quis pharetra. Mattis sem proin integer faucibus. Sit ac lobortis potenti a ipsum morbi. Enim risus vitae nisi neque vel malesuada a. Nam neque bibendum urna ut ipsum. Sit eget odio habitasse diam tristique diam sodales. Tempor consectetur enim vivamus fusce et bibendum. Eu ac facilisis quam lacus massa. Donec accumsan molestie nec id eu fermentum nulla. Condimentum erat faucibus sit integer dignissim faucibus turpis eleifend purus. Nulla diam pellentesque amet velit sapien integer sed vitae. Sagittis venenatis viverra pulvinar fusce vitae nec donec. Massa leo cursus at cras in vel tempor purus. Nisi tortor porttitor est nunc nunc. Amet pharetra nullam sollicitudin iaculis maecenas volutpat quam pellentesque. Odio urna sit blandit posuere ultricies. Quam donec enim nunc aliquet commodo nunc. Tristique orci imperdiet malesuada faucibus diam scelerisque dictum sagittis. Viverra sit nisi in amet pulvinar tempus viverra quis. Interdum sit nunc lectus sed a viverra egestas et fusce. Malesuada tristique massa integer platea. Arcu nunc sit lorem commodo condimentum cursus. Nunc enim enim nec donec quam purus gravida cursus. Id nibh varius in diam nunc sed lorem.",
  score: getRandomNumber(55, 100),
  judges: getRandomNumber(10, 25),
  rank: getRandomNumber(1, 1000),
};

export const handleLimitCut = (text: string, limit: number) => {
  const cutEnd_regex = "/s+?[^s]*?$/";
  if (text.length > limit) {
    const slicedText = text.slice(0, limit - 3);
    if (slicedText.match(cutEnd_regex)) {
      return slicedText.replace(cutEnd_regex, "...");
    } else {
      return slicedText + "...";
    }
  }
  return text;
};

export const getRankSuffix = (rank: number) => {
  const pos = ["", "st", "nd", "rd", "th"];
  let value = rank % 10;
  if (value > 3) {
    return pos[4];
  }
  return pos[value];
};

function getRandomNumber(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export const handleDate = (date: number) => {
  const dateDiff = date - new Date().getDate();
  if (dateDiff < 1) {
    return `${-1 * dateDiff} ${-1 * dateDiff > 1 ? "days" : "day"} ago`;
  } else {
    return `In ${dateDiff} ${dateDiff > 1 ? "days" : "day"} time`;
  }
};

export const competitions: hostPost[] = [
  {
    description:
      "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
    datetime: 27,
    attendees: 23,
    school: "Madonna model secondary school",
    imageUrl: "",
    tags: [
      "Essay",
      "School project",
      "Shopping",
      "Activities",
      "Highschool",
      "300 words",
    ],
    createdAt: "12, Apr 2012",
    price: 120000,
    completed: true,
    id: Math.random().toString(36).substring(2, 9),
  },
  {
    description:
      "Having and having more free time. Discuss both views and state your opinion.",
    datetime: 20,
    attendees: 29,
    school: "Madonna model secondary school",
    imageUrl: "",
    tags: [
      "Essay",
      "School project",
      "Shopping",
      "Activities",
      "Highschool",
      "300 words",
    ],
    createdAt: "12, Apr 2012",
    price: 120000,
    completed: false,
    id: Math.random().toString(36).substring(2, 9),
  },
  {
    description:
      "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
    datetime: 28,
    attendees: 23,
    school: "Madonna model secondary school",
    imageUrl: "",
    tags: [
      "Essay",
      "School project",
      "Shopping",
      "Activities",
      "Highschool",
      "300 words",
    ],
    createdAt: "12, Apr 2012",
    price: 120000,
    completed: false,
    id: Math.random().toString(36).substring(2, 9),
  },
  {
    description:
      "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
    datetime: 26,
    attendees: 1,
    school: "Madonna model secondary school",
    imageUrl: "",
    tags: [
      "Essay",
      "School project",
      "Shopping",
      "Activities",
      "Highschool",
      "300 words",
    ],
    createdAt: "12, Apr 2012",
    price: 120000,
    completed: false,
    id: Math.random().toString(36).substring(2, 9),
  },
  {
    description:
      "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
    datetime: 20,
    attendees: 23,
    school: "Madonna model secondary school",
    imageUrl: "",
    tags: [
      "Essay",
      "School project",
      "Shopping",
      "Activities",
      "Highschool",
      "300 words",
    ],
    createdAt: "12, Apr 2012",
    price: 120000,
    completed: false,
    id: Math.random().toString(36).substring(2, 9),
  },
  {
    description:
      "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
    datetime: 20,
    attendees: 23,
    school: "Madonna model secondary school",
    imageUrl: "",
    tags: [
      "Essay",
      "School project",
      "Shopping",
      "Activities",
      "Highschool",
      "300 words",
    ],
    createdAt: "12, Apr 2012",
    price: 120000,
    completed: false,
    id: Math.random().toString(36).substring(2, 9),
  },
  {
    description:
      "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
    datetime: 2,
    attendees: 23,
    school: "Madonna model secondary school",
    imageUrl: "",
    tags: [
      "Essay",
      "School project",
      "Shopping",
      "Activities",
      "Highschool",
      "300 words",
    ],
    createdAt: "12, Apr 2012",
    price: 120000,
    completed: false,
    id: Math.random().toString(36).substring(2, 9),
  },
  {
    description:
      "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
    datetime: 20,
    attendees: 2,
    school: "Madonna model secondary school",
    imageUrl: "",
    tags: [
      "Essay",
      "School project",
      "Shopping",
      "Activities",
      "Highschool",
      "300 words",
    ],
    createdAt: "12, Apr 2012",
    price: 120000,
    completed: false,
    id: Math.random().toString(36).substring(2, 9),
  },
  {
    description: "Having more money and less free time is better than earning.",
    datetime: 31,
    attendees: 23,
    school: "Madonna model secondary school",
    imageUrl: "",
    tags: [
      "Essay",
      "School project",
      "Shopping",
      "Activities",
      "Highschool",
      "300 words",
    ],
    createdAt: "12, Apr 2012",
    price: 120000,
    completed: false,
    id: Math.random().toString(36).substring(2, 9),
  },
  {
    description:
      "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
    datetime: 20,
    attendees: 23,
    school: "Madonna model secondary school",
    imageUrl: "",
    tags: [
      "Essay",
      "School project",
      "Shopping",
      "Activities",
      "Highschool",
      "300 words",
    ],
    createdAt: "12, Apr 2012",
    price: 120000,
    completed: false,
    id: Math.random().toString(36).substring(2, 9),
  },
  {
    description:
      "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
    datetime: 20,
    attendees: 23,
    school: "Madonna model secondary school",
    imageUrl: "",
    tags: [
      "Essay",
      "School project",
      "Shopping",
      "Activities",
      "Highschool",
      "300 words",
    ],
    createdAt: "12, Apr 2012",
    price: 120000,
    completed: false,
    id: Math.random().toString(36).substring(2, 9),
  },
  {
    description:
      "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
    datetime: 30,
    attendees: 1,
    school: "Madonna model secondary school",
    imageUrl: "",
    tags: [
      "Essay",
      "School project",
      "Shopping",
      "Activities",
      "Highschool",
      "300 words",
    ],
    createdAt: "12, Apr 2012",
    price: 120000,
    completed: false,
    id: Math.random().toString(36).substring(2, 9),
  },
  {
    description:
      "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
    datetime: 20,
    attendees: 23,
    school: "Madonna model secondary school",
    imageUrl: "",
    tags: [
      "Essay",
      "School project",
      "Shopping",
      "Activities",
      "Highschool",
      "300 words",
    ],
    createdAt: "12, Apr 2012",
    price: 120000,
    completed: false,
    id: Math.random().toString(36).substring(2, 9),
  },
  {
    description:
      "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
    datetime: 29,
    attendees: 23,
    school: "Madonna model secondary school",
    imageUrl: "",
    tags: [
      "Essay",
      "School project",
      "Shopping",
      "Activities",
      "Highschool",
      "300 words",
    ],
    createdAt: "12, Apr 2012",
    price: 120000,
    completed: false,
    id: Math.random().toString(36).substring(2, 9),
  },
];
