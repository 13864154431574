import React from "react";
import { socialIcons } from "./constant";

const ShareComponent: React.FC = () => {
  return (
    <div className="px-4 py-3 flex flex-col rounded-[11px] gap-2 bg-lightGray text-primary font-open_sans font-normal text-sm leading-[22.4px]">
      <h6 className="">Share Contest:</h6>
      <div className="flex flex-wrap gap-6">
        {socialIcons.map((icon, i) => {
          return (
            <div
              className="flex border items-center justify-center h-10 w-10 bg-white border-[#E9EBEE] rounded"
              key={i}
            >
              <img src={icon} alt="social icon" />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ShareComponent;
