const Settings: React.FC = () => {
  return (
    <div className="flex relative flex-col min-vh h-full max-vw w-full left-[328px] rounded-[11px] gap-2 text-primary font-open_sans font-normal text-sm leading-[22.4px]">
      <div className="bg-white w-full p-6 flex flex-col gap-3">
        <h5 className="font-raleway font-bold text-2xl leading-[140%]">
          Settings Page
        </h5>
      </div>
      <div className="bg-white flex-1 flex flex-col gap-4 p-6 w-full h-full"></div>
    </div>
  );
};

export default Settings;
