import React from "react";

interface Props {
  name: string;
  HTMLForId: string;
  check: boolean;
  handleChange: () => void;
}
const Switch: React.FC<Props> = ({ name, HTMLForId, check, handleChange }) => {
  return (
    <div className="relative w-[51px] h-[31px]" onClick={handleChange}>
      <input
        className="peer h-0 w-0 opacity-0"
        type="checkbox"
        name={name}
        id={HTMLForId}
        checked={check}
        onChange={handleChange}
      />
      <span className="check-slider"></span>
    </div>
  );
};

export default Switch;
