import React, { useState } from "react";
import { Button } from "../buttons";

interface Props {
  numWords: number;
  setNumWords: React.Dispatch<React.SetStateAction<number>>;
  ongoing?: boolean;
}
const Register: React.FC<Props> = ({ numWords, setNumWords, ongoing }) => {
  const [feeOpen, setFeeOpen] = useState<boolean>(false);
  const runTwiceRegister = () => {
    let countClick = 0;
    return () => {
      countClick++;
      if (countClick < 2 && !feeOpen) {
        setFeeOpen(true);
      } else {
        // words limit
        countClick = 0;
        // test purpose
        setNumWords(15);
        setFeeOpen(false);
      }
    };
  };
  const handleClick = runTwiceRegister();
  return (
    <div className="bg-primary h-full p-6 flex flex-col gap-6 text-base font-open_sans text-primary leading-[25.6px] font-semibold">
      <div>
        <h4 className="text-4xl leading-[50.4px] font-extrabold font-raleway">
          {ongoing ? "Ongoing" : "Register"}
        </h4>
        <p>Take note of the following</p>
      </div>
      <div>
        <p className="flex flex-col pb-6 border-b border-white">
          <span className="text-sm leading-[19.07px]">Prize</span>
          <span className="text-2xl not-italic leading-[33.6px] font-extrabold">
            NGN 120,000
          </span>
        </p>
        <p className="flex flex-col py-6 border-b border-white">
          <span className="text-sm leading-[19.07px]">Duration</span>
          <span className="text-2xl font-raleway  leading-[33.6px] font-extrabold">
            7 days
          </span>
          <span className="text-sm leading-[19.07px] text-orange">
            4 days left
          </span>
        </p>
        <div className="flex flex-col  gap-3 py-6 border-b border-white">
          <div className="p-4 rounded-[11px] bg-white flex flex-col gap-4">
            <p className=" text-xs leading-[16.34px] text-[#EA3883] ">
              Required documents
            </p>
            <p className="font-raleway font-extrabold leading-[22.4px]">
              Some document
            </p>
            <hr />
            <p className="font-raleway font-extrabold leading-[22.4px]">
              Some other document
            </p>
          </div>
          <p className="text-center text-sm leading-[19.07px]">
            Attach the documents above to your submission
          </p>
        </div>
        <p className=" flex flex-col pt-6">
          <span>Submission requirements</span>
          <em className="text-2xl not-italic leading-[33.6px] font-extrabold">
            3500 words
          </em>
        </p>
      </div>

      <div
        className={`${
          feeOpen ? "h-[46px]" : "h-0"
        }  items-center px-4 overflow-hidden transition delay-[45] duration-500 ease-in-out bg-transparentGray rounded-[11px] flex justify-between`}
      >
        <span>Fee</span>
        <span>NGN 1,000</span>
      </div>

      <div className="max-w-[240px] w-full self-center flex flex-col gap-2">
        <p className=" text-sm font-normal self-center">
          23 participants registered
        </p>
        <div onClick={handleClick}>
          <Button type="submit" />
        </div>
      </div>
    </div>
  );
};

export default Register;
