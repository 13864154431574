import React, { useState } from "react";
import HeroHeader from "../landing/HeroHeader";
import CompetitionCard from "../../reusable/competition_card/CompetitionCard";
import { hostPost } from "../../reusable/competition_card/constant";
/* Mock data*/
import { competitions } from "../../reusable/competition_card/constant";

/* Swiper import */
import { Swiper as SwiperContainer, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";

/* Swipper css */
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import CardModal from "../../reusable/competition_card/CardModal";

const DashBoard: React.FC = () => {
  const [openCard, setOpenCard] = useState<undefined | hostPost>(undefined);
  console.log(openCard);
  // @ts-ignore
    return (
    <div className="pt-[90px] h-screen relative">
      <HeroHeader />
      <h4 className="ml-16 font-raleway font-bold text-2xl leading-[33.6px] text-primary">
        Latest Competitions
      </h4>

      {openCard && (
        <CardModal setOpenCard={setOpenCard} openCard={openCard} />
      )}
      <div className="flex my-5">
        <SwiperContainer
          slidesPerView={2.5}
          centeredSlides
          loopedSlides={3}
          loop
          breakpoints={{
            996: {
              slidesPerView: 3.5,
              loopedSlides: 5,
            },
            1400: {
              slidesPerView: 4.5,
              loopedSlides: 5,
            },
          }}
          modules={[Navigation, Autoplay]}
          speed={1000}
          watchSlidesProgress={true}
          // autoplay={{ disableOnInteraction: false, delay: 5000 }}
          navigation
        >
          {competitions.map((item, i) => {
            return (
              <SwiperSlide>
                <CompetitionCard
                  openCard={openCard}
                  setOpenCard={setOpenCard}
                  post={item}
                  user
                />
              </SwiperSlide>
            );
          })}
        </SwiperContainer>
      </div>
    </div>
  );
};
export default DashBoard;
