import React from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  from?: string;
}
const BackButton: React.FC<Props> = ({ from = "" }) => {
  const navigate = useNavigate();
  const navigateTo = () => navigate(from);
  const navigateDelta = () => navigate(-1);
  return (
    <button
      type="button"
      className="flex gap-1 items-center text-primary font-open_sans font-normal text-base leading-[160%] back-button"
      onClick={from ? () => navigateTo() : () => navigateDelta()}
    >
      Go back
    </button>
  );
};

export default BackButton;
