import React from "react";
import { Button } from "../../reusable/buttons";

interface Props {
  success: boolean;
  handleClose: () => void;
}
const ConfirmModal: React.FC<Props> = ({ success, handleClose }) => {
  return (
    <div
      className={`${
        success ? " translate-y-0" : " translate-y-full"
      } fixed transition delay-[45] ease-in-out duration-200 z-10 bg-transparentDark flex items-center justify-center right-0 left-0 bottom-0 top-0`}
    >
      <div className="bg-white rounded-[22px] p-6 max-w-[468px] flex flex-col gap-6">
        <div className="flex flex-col items-center self-center">
          <div
            className="w-[64px] h-[64px] bg-no-repeat center"
            style={{ backgroundImage: `url("assets/icons/check.png")` }}
          ></div>
          <p className=" font-raleway font-bold text-[28px] leading-[33px] text-center">
            Submission sent <br />
            successfully
          </p>
        </div>
        <p className=" font-open_sans font-semibold text-lg leading-[24.51px] text-center">
          You have successfully sent in your submission, you will be alerted
          when the review is concluded
        </p>
        <div onClick={handleClose}>
          <Button type="button" text="Go back" />
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
