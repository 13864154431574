import React from "react";
import { Outlet } from "react-router-dom";
import NavBar from "../components/reusable/navBar";

const Home: React.FC = () => {
  return (
    <>
      <NavBar />
      {/* login/signups */}
      <Outlet />
    </>
  );
};

export default Home;
