import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import NextIcon from "./NextIcon";

interface Props {
  link: string;
  name: string;
}

const SideLInk: React.FC<Props> = ({ link, name }) => {
  const [active, setActive] = useState<boolean>(false);
  const navLinkRef = useRef<HTMLAnchorElement>(null);
  const { pathname } = useLocation();

  useEffect(() => {
    if (navLinkRef?.current?.classList.contains("active")) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [pathname]);
  return (
    <NavLink
      ref={navLinkRef}
      to={link}
      className={({ isActive, isPending }) =>
        isActive
          ? "sidelink active"
          : isPending
          ? "sidelink pending"
          : "sidelink"
      }
    >
      <span>{name}</span>
      <NextIcon active={active} />
    </NavLink>
  );
};

export default SideLInk;

// import React, { useEffect, useRef, useState } from "react";
// import {
//   useLocation,
//   useNavigate,
//   useNavigation,
//   Link,
// } from "react-router-dom";
// import { NavLink } from "react-router-dom";
// import NextIcon from "./NextIcon";

// interface Props {
//   link: string;
//   name: string;
// }

// const SideLInk: React.FC<Props> = ({ link, name }) => {
//   const [active, setActive] = useState<boolean>(false);
//   const navLinkRef = useRef<HTMLAnchorElement>(null);
//   const { pathname } = useLocation();
//   console.log(pathname);

//   const fullLink = `/explore/${link}`;

//   //   useEffect(() => {
//   //     if (navLinkRef?.current?.classList.contains("active")) {
//   //       setActive(true);
//   //     } else {
//   //       setActive(false);
//   //     }
//   //   }, [location.pathname]);
//   return (
//       <Link
//         ref={navLinkRef}
//         to={link}
//         className={`${pathname === fullLink ? "active" : ""} sidelink`}
//       >
//         <span>{name}</span>
//          <NextIcon active={pathname === fullLink} />
//       </Link>
//   );
// };

// export default SideLInk;
