import React, { useState } from "react";
import { Switch } from "../../reusable/buttons";

const JudgeSwitch: React.FC = () => {
  const [check, setCheck] = useState<boolean>(false);
  const handleCheck = () => {
    setCheck(!check);
  };
  return (
    <div className="flex justify-between items-center py-3 px-4 rounded-[11px] border border-[#E9EBEE]">
      <label
        htmlFor="open_to_judge"
        className="font-open_sans font-semibold cursor-pointer text-base leading-[21.79px] text-secondary "
      >
        Open to Judge
      </label>
      <Switch
        name="open_to_judge"
        HTMLForId="open_to_judge"
        check={check}
        handleChange={handleCheck}
      />
    </div>
  );
};

export default JudgeSwitch;
