export interface sideMenuLink {
  name: string;
  link: string;
}

export const MENULINKS: sideMenuLink[] = [
  {
    name: "Dashboard",
    link: "dashboard",
  },
  {
    name: "Results",
    link: "results",
  },
  {
    name: "Judge section",
    link: "judge_section",
  },
  {
    name: "Settings",
    link: "settings",
  },
];
