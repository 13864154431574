import React from "react";
const tags = [
  "Essay",
  "School project",
  "Shopping",
  "Activities",
  "Highschool",
  "300 words",
];
const Tags: React.FC = () => {
  return (
    <div className="px-4 py-3 flex flex-col rounded-[11px] gap-2 bg-lightGray text-primary font-open_sans font-normal text-sm leading-[22.4px]">
      <h6 className="">Tags:</h6>
      <div className="flex flex-wrap gap-2 ">
        {tags.map((tag, i) => {
          return (
            <span
              className="text-center block shadow-light rounded-[11px] py-3 px-4 bg-white"
              key={i}
            >
              {tag}
            </span>
          );
        })}
      </div>
    </div>
  );
};

export default Tags;
