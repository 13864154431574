import React from "react";
import { mockInfo, requirements } from "./constant";
import { Button } from "../buttons";

interface Props {
  moreInfo: null | requirements; // expects from post information
  setMoreInfo: React.Dispatch<React.SetStateAction<requirements | null>>;
}
const Requirements: React.FC<Props> = ({ moreInfo, setMoreInfo }) => {
  return (
    <div className="font-open_sans flex flex-col gap-6  text-base text-primary leading-[25.6px] p-6 font-semibold bg-lightGray">
      <div>
        <h4 className="text-4xl leading-[50.4px] font-extrabold font-raleway">
          Description
        </h4>
        <p>
          Shopping is the favourite pastime for most of the young people.
          <br /> Why do you think is that? <br />
          Do you think they should be encouraged to do some other useful
          activities?
        </p>
      </div>
      <ol>
        <h4 className="text-4xl leading-[50.4px] font-extrabold font-raleway">
          Requirements
        </h4>
        {/* TODO! update from post information */}
        {mockInfo.requirements.map((item, i) => {
          let idx = i + 1;
          if (idx > 3) {
            return "";
          }
          return (
            <li key={idx} className="grid-requirement gap-3">
              <span>{idx}.</span>
              {idx === 3 ? item + "..." : item}
            </li>
          );
        })}
      </ol>
      <div
        className="w-[240px] self-center"
        onClick={() => setMoreInfo(mockInfo)}
      >
        <Button type="button" bg="transparent" text="Read More" />
      </div>
    </div>
  );
};

export default Requirements;
