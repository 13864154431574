import { hostPost } from "../../reusable/competition_card/constant";

export interface overviewType {
  title: string;
  details: string;
}
type postTabs = {
  [key: string]: hostPost[];
};

export const DASHBOARDOVERVIEW: overviewType[] = [
  {
    title: "Total revenue generated",
    details: "NGN 1,000,000",
  },
  {
    title: "Total fee paid",
    details: "NGN 72,000",
  },
];
export const JUDGEOVERVIEW: overviewType[] = [
  {
    title: "Total contestants judged",
    details: "370",
  },
  {
    title: "Completion rate",
    details: "57%",
  },
];

export const BACKGROUND = ["rgba(33, 150, 83, 0.1)", "rgba(235, 87, 87, 0.1)"];
export const ALLPOSTs: postTabs = {
  "competitions ongoing": [
    {
      description:
        "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
      datetime: 20,
      attendees: 23,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: false,
      id: Math.random().toString(36).substring(2, 9),
    },
    {
      description:
        "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
      datetime: 30,
      attendees: 1,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: false,
      id: Math.random().toString(36).substring(2, 9),
    },
    {
      description:
        "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
      datetime: 20,
      attendees: 23,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: false,
      id: Math.random().toString(36).substring(2, 9),
    },
  ],
  "competitions registered": [
    {
      description:
        "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
      datetime: 28,
      attendees: 23,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: true,
      id: Math.random().toString(36).substring(2, 9),
    },
    {
      description:
        "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
      datetime: 26,
      attendees: 1,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: true,
      id: Math.random().toString(36).substring(2, 9),
    },
    {
      description:
        "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
      datetime: 20,
      attendees: 23,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: true,
      id: Math.random().toString(36).substring(2, 9),
    },
    {
      description:
        "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
      datetime: 20,
      attendees: 23,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: true,
      id: Math.random().toString(36).substring(2, 9),
    },
    {
      description:
        "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
      datetime: 2,
      attendees: 23,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: true,
      id: Math.random().toString(36).substring(2, 9),
    },
    {
      description:
        "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
      datetime: 20,
      attendees: 2,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: true,
      id: Math.random().toString(36).substring(2, 9),
    },
    {
      description:
        "Having more money and less free time is better than earning.",
      datetime: 31,
      attendees: 23,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: true,
      id: Math.random().toString(36).substring(2, 9),
    },
    {
      description:
        "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
      datetime: 20,
      attendees: 23,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: true,
      id: Math.random().toString(36).substring(2, 9),
    },
    {
      description:
        "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
      datetime: 20,
      attendees: 23,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: true,
      id: Math.random().toString(36).substring(2, 9),
    },
    {
      description:
        "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
      datetime: 30,
      attendees: 1,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: true,
      id: Math.random().toString(36).substring(2, 9),
    },
    {
      description:
        "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
      datetime: 20,
      attendees: 23,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: true,
      id: Math.random().toString(36).substring(2, 9),
    },
    {
      description:
        "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
      datetime: 29,
      attendees: 23,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: true,
      id: Math.random().toString(36).substring(2, 9),
    },
    {
      description:
        "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
      datetime: 28,
      attendees: 23,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: true,
      id: Math.random().toString(36).substring(2, 9),
    },
    {
      description:
        "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
      datetime: 26,
      attendees: 1,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: true,
      id: Math.random().toString(36).substring(2, 9),
    },
    {
      description:
        "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
      datetime: 20,
      attendees: 23,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: true,
      id: Math.random().toString(36).substring(2, 9),
    },
    {
      description:
        "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
      datetime: 20,
      attendees: 23,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: true,
      id: Math.random().toString(36).substring(2, 9),
    },
    {
      description:
        "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
      datetime: 2,
      attendees: 23,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: true,
      id: Math.random().toString(36).substring(2, 9),
    },
    {
      description:
        "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
      datetime: 20,
      attendees: 2,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: true,
      id: Math.random().toString(36).substring(2, 9),
    },
    {
      description:
        "Having more money and less free time is better than earning.",
      datetime: 31,
      attendees: 23,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: true,
      id: Math.random().toString(36).substring(2, 9),
    },
    {
      description:
        "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
      datetime: 20,
      attendees: 23,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: true,
      id: Math.random().toString(36).substring(2, 9),
    },
    {
      description:
        "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
      datetime: 20,
      attendees: 23,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: true,
      id: Math.random().toString(36).substring(2, 9),
    },
    {
      description:
        "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
      datetime: 30,
      attendees: 1,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: true,
      id: Math.random().toString(36).substring(2, 9),
    },
    {
      description:
        "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
      datetime: 20,
      attendees: 23,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: true,
      id: Math.random().toString(36).substring(2, 9),
    },
    {
      description:
        "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
      datetime: 29,
      attendees: 23,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: true,
      id: Math.random().toString(36).substring(2, 9),
    },
  ],
  "competitions won": [
    {
      description:
        "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
      datetime: 28,
      attendees: 23,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: true,
      id: Math.random().toString(36).substring(2, 9),
    },
    {
      description:
        "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
      datetime: 26,
      attendees: 1,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: true,
      id: Math.random().toString(36).substring(2, 9),
    },
    {
      description:
        "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
      datetime: 20,
      attendees: 23,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: true,
      id: Math.random().toString(36).substring(2, 9),
    },
    {
      description:
        "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
      datetime: 20,
      attendees: 23,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: true,
      id: Math.random().toString(36).substring(2, 9),
    },
    {
      description:
        "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
      datetime: 2,
      attendees: 23,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: true,
      id: Math.random().toString(36).substring(2, 9),
    },
    {
      description:
        "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
      datetime: 20,
      attendees: 2,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: true,
      id: Math.random().toString(36).substring(2, 9),
    },
    {
      description:
        "Having more money and less free time is better than earning.",
      datetime: 31,
      attendees: 23,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: true,
      id: Math.random().toString(36).substring(2, 9),
    },
    {
      description:
        "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
      datetime: 20,
      attendees: 23,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: true,
      id: Math.random().toString(36).substring(2, 9),
    },
    {
      description:
        "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
      datetime: 20,
      attendees: 23,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: true,
      id: Math.random().toString(36).substring(2, 9),
    },
    {
      description:
        "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
      datetime: 30,
      attendees: 1,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: true,
      id: Math.random().toString(36).substring(2, 9),
    },
    {
      description:
        "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
      datetime: 20,
      attendees: 23,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: true,
      id: Math.random().toString(36).substring(2, 9),
    },
    {
      description:
        "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
      datetime: 29,
      attendees: 23,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: true,
      id: Math.random().toString(36).substring(2, 9),
    },
  ],
  "competitions judged": [],
};

//
//
//
//
//
//
//
//
//
//
export const JUDGETAB: postTabs = {
  "pending judgements": [
    {
      description:
        "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
      datetime: 20,
      attendees: 23,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: false,
      id: Math.random().toString(36).substring(2, 9),
    },
    {
      description:
        "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
      datetime: 30,
      attendees: 1,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: false,
      id: Math.random().toString(36).substring(2, 9),
    },
    {
      description:
        "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
      datetime: 20,
      attendees: 23,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: false,
      id: Math.random().toString(36).substring(2, 9),
    },
  ],
  "competitions judged": [
    {
      description:
        "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
      datetime: 28,
      attendees: 23,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: true,
      id: Math.random().toString(36).substring(2, 9),
    },
    {
      description:
        "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
      datetime: 26,
      attendees: 1,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: true,
      id: Math.random().toString(36).substring(2, 9),
    },
    {
      description:
        "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
      datetime: 20,
      attendees: 23,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: true,
      id: Math.random().toString(36).substring(2, 9),
    },
    {
      description:
        "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
      datetime: 20,
      attendees: 23,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: true,
      id: Math.random().toString(36).substring(2, 9),
    },
    {
      description:
        "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
      datetime: 2,
      attendees: 23,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: true,
      id: Math.random().toString(36).substring(2, 9),
    },
    {
      description:
        "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
      datetime: 20,
      attendees: 2,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: true,
      id: Math.random().toString(36).substring(2, 9),
    },
    {
      description:
        "Having more money and less free time is better than earning.",
      datetime: 31,
      attendees: 23,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: true,
      id: Math.random().toString(36).substring(2, 9),
    },
    {
      description:
        "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
      datetime: 20,
      attendees: 23,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: true,
      id: Math.random().toString(36).substring(2, 9),
    },
    {
      description:
        "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
      datetime: 20,
      attendees: 23,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: true,
      id: Math.random().toString(36).substring(2, 9),
    },
    {
      description:
        "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
      datetime: 30,
      attendees: 1,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: true,
      id: Math.random().toString(36).substring(2, 9),
    },
    {
      description:
        "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
      datetime: 20,
      attendees: 23,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: true,
      id: Math.random().toString(36).substring(2, 9),
    },
    {
      description:
        "Having more money and less free time is better than earning less money and having more free time. Discuss both views and state your opinion.",
      datetime: 29,
      attendees: 23,
      school: "Madonna model secondary school",
      imageUrl: "",
      tags: [
        "Essay",
        "School project",
        "Shopping",
        "Activities",
        "Highschool",
        "300 words",
      ],
      createdAt: "12, Apr 2012",
      price: 120000,
      completed: true,
      id: Math.random().toString(36).substring(2, 9),
    },
  ],
};
