import React, { useEffect, useRef, useState } from "react";
import { Button } from "../../reusable/buttons";
import FileInput from "./FileInput";
import ConfirmModal from "./ConfirmModal";
import { hostPost } from "../competition_card/constant";

interface Props {
  numWords: number; // expects from post information
  setNumWords: React.Dispatch<React.SetStateAction<number>>;
  setOpenCard: React.Dispatch<React.SetStateAction<undefined | hostPost>>;
}

interface wordsAndCount {
  content: string;
  count: number;
}
const CompetitionForm: React.FC<Props> = ({
  setNumWords,
  numWords,
  setOpenCard,
}) => {
  const [focus, setFocus] = useState<boolean>(false);
  const [PDFError, setPDFError] = useState<boolean>(false);
  const [errorState, setErrorState] = useState<boolean>(false);
  const [textAreaError, setTextAreaError] = useState<boolean>(false);
  const [file1, setFile1] = useState<null | File>(null);
  const [file2, setFile2] = useState<null | File>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const [{ count, content }, setWordCount] = useState<wordsAndCount>({
    content: "",
    count: 0,
  });

  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const file1Ref = useRef<HTMLInputElement>(null);
  const file2Ref = useRef<HTMLInputElement>(null);

  const handleFileInputs = () => {
    setErrorState(Boolean(!file1 || !file2) ? true : false);
    return file1 && file2;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!handleFileInputs()) {
      return;
    }
    if (count !== numWords) {
      setTextAreaError(true);
      return;
    }
    const formdata = new FormData(e.currentTarget);
    const values = Object.fromEntries(formdata);
    const newObject: object = {
      file1: file1,
      file2: file1,
      ...values,
    };
    setSuccess(true);
    e.currentTarget.reset();
    console.log(newObject);
  };

  const handleSelect = async () => {
    const inputs = [file1, file2];
    const inputRefs = [file1Ref, file2Ref];
    for (let i = 0; i < inputs.length; i++) {
      let changed = false;
      if (!inputs[i]) {
        changed = true;
        inputRefs[i]?.current?.click();
        if (i !== 1) {
          return;
        }
      }
      if (changed) {
        await new Promise((res) =>
          setTimeout(() => {
            res(setErrorState(false));
          }, 5000)
        );
      }
    }
  };

  // handle word limit
  const handleWordLimit = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const text = e.target.value;
    const words = text.split(" ").filter(Boolean);
    if (words.length >= numWords) {
      setWordCount({
        content: words.slice(0, numWords).join(" "),
        count: numWords,
      });
    } else {
      setWordCount({
        content: text,
        count: words.length,
      });
    }
  };
  const handleCleanUp = () => {
    setSuccess(false);
    setNumWords(0);
    setOpenCard(undefined);
  };

  // change view on blur of textarea
  useEffect(() => {
    setFocus(!focus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textAreaRef.current?.blur]);

  useEffect(() => {
    const func = async () => {
      await new Promise((res) => {
        setTimeout(() => {
          res(setPDFError(false));
        }, 4000);
      });
    };
    func();
  }, [PDFError]);

  useEffect(() => {
    const func = async () => {
      await new Promise((res) => {
        setTimeout(() => {
          res(setTextAreaError(false));
        }, 800);
      });
    };
    func();
  }, [textAreaError]);

  return (
    <form
      className="font-open_sans h-full relative flex text-xl text-primary font-bold bg-lightGray"
      onSubmit={handleSubmit}
    >
      <div
        className={`${
          PDFError
            ? " translate-y-0 visible opacity-100"
            : " translate-y-[-100%] opacity-0 invisible"
        } absolute bg-white font-raleway rounded-[11px] transition delay-[45] ease-in-out duration-200 right-1/2 text-base  text-danger p-6`}
      >
        only PDF files allowed *
      </div>
      <div className="max-w-[789.33px] py-6 pl-6 w-full flex flex-col">
        <h4 className="text-4xl leading-[50.4px] font-extrabold font-raleway">
          Submission
        </h4>
        <ConfirmModal success={success} handleClose={handleCleanUp} />
        <div className="w-full relative flex flex-col h-full text-base leading-[160%] font-semibold">
          <textarea
            ref={textAreaRef}
            value={content}
            onFocus={() => setFocus(!focus)}
            onBlur={() => setFocus(false)}
            onInput={handleWordLimit}
            className={`
            ${textAreaError ? "border-danger" : "border-transparent"}
            w-full max-h-[789px] transition delay-[45] ease-in-out duration-500 border rounded-[8px] flex-1 resize-none placeholder:text-gray bg-transparent h-full  outline-none`}
            name="essay"
            id="essay"
            placeholder="Start Typing..."
          ></textarea>
          <div
            className={`${
              errorState ? " translate-y-0" : " translate-y-full"
            }  transition delay-[45] ease-in-out duration-200
          absolute flex items-center justify-center right-0 left-0 bottom-0 top-0 bg-lightGray`}
          >
            <div className="flex flex-col items-center gap-4">
              <div className="flex items-center flex-col gap-2">
                <p className=" font-raleway font-extrabold text-xl leading-[140%]">
                  Select document to submit
                </p>
                <p className=" font-semibold text-lg leading-[25px]">
                  Quam nunc arcu urna hendrerit diam. Dictum fermentum quam.
                </p>
              </div>
              <div className="max-w-[346.36px] w-full" onClick={handleSelect}>
                <Button type="button" bg="transparent" text="Select file" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col gap-4 relative flex-1 p-6 bg-white">
        <div
          onClick={() => setNumWords(0)}
          className="absolute right-0 rounded-es-full bg-transparent bg-no-repeat bg-center h-[86px] w-[86px] top-0"
          style={{
            backgroundImage: `url("/assets/icons/x.png")`,
          }}
        ></div>
        <h4 className="text-4xl leading-[50.4px] font-extrabold font-raleway">
          Requirements
        </h4>
        <p className="flex flex-col pb-6 border-b border-[#E9EBEE]">
          <span className="text-sm leading-[19.07px] font-semibold">
            {focus ? "Counter" : "Submission file type"}
          </span>
          <span>{focus ? `${count}/${numWords} words` : "PDF"}</span>
        </p>
        <div className="pb-6 border-b border-[#E9EBEE]  flex flex-col gap-4">
          <p className=" text-xs leading-[16.34px] text-[#EA3883] ">
            Required documents
          </p>
          <p className="flex flex-col">
            <span className="font-semibold">Document 1</span>
            <span>Some document</span>
          </p>
          <div className="flex flex-col gap-2">
            <span className="text-sm leading-[19.07px] font-semibold">
              Upload pdf
            </span>
            <div>
              <FileInput
                name="file1"
                file={file1}
                setFile={setFile1}
                error={errorState}
                refs={file1Ref}
                setPDFError={setPDFError}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <p className="flex flex-col">
            <span className="font-semibold">Document 1</span>
            <span>Some document</span>
          </p>
          <div className="flex flex-col gap-2">
            <span className="text-sm leading-[19.07px] font-semibold">
              Upload pdf
            </span>
            <div>
              <FileInput
                name="file2"
                file={file2}
                setFile={setFile2}
                error={errorState}
                refs={file2Ref}
                setPDFError={setPDFError}
              />
            </div>
          </div>
        </div>
        <div className="mt-auto">
          <Button type="submit" text="Submit" />
        </div>
      </div>
    </form>
  );
};
export default CompetitionForm;
