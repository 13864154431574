import React, { useRef } from "react";

// interface Props {
//   total: number;
//   title: string;
//   selected: boolean;
//   setSelected: React.Dispatch<React.SetStateAction<string>>;
// }
const JudgeTab: React.FC = () => {
  const prevRef = useRef<HTMLSpanElement>(null);
  const nextRef = useRef<HTMLSpanElement>(null);

  const handleAllSelect = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.nativeEvent.stopImmediatePropagation();
    nextRef.current?.parentElement?.classList.add("active");
  };
  const handleNotJudgedSelect = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.nativeEvent.stopImmediatePropagation();

    if (nextRef.current?.parentElement?.classList.contains("active")) {
      nextRef.current.parentElement?.classList.remove("active");
    }
  };
  return (
    <div className="flex w-full justify-evenly h-10 relative bg-gray judge-switch">
      <span
        ref={prevRef}
        className="flex-1 text-center relative z-10 prev"
        onClick={handleAllSelect}
      >
        all
      </span>
      <span
        ref={nextRef}
        onClick={handleNotJudgedSelect}
        className="flex-1 self-center text-center  relative z-10 next"
      >
        not judged
      </span>
    </div>
  );
};

export default JudgeTab;
