import React from "react";
import { Button } from "../../reusable/buttons";
import { getRankSuffix, handleLimitCut, submission } from "./constant";

interface Props {
  veiwSubmission: submission | null;
  setViewSubmission: React.Dispatch<React.SetStateAction<submission | null>>;
  setMore: React.Dispatch<React.SetStateAction<string>>;
}
const SubmissionCard: React.FC<Props> = ({
  setViewSubmission,
  veiwSubmission,
  setMore,
}) => {
  return (
    <div className="font-open_sans h-full relative flex text-xl text-primary font-bold bg-lightGray">
      <div className="max-w-[789.33px] p-6 w-full flex flex-col">
        <h4 className="text-4xl leading-[50.4px] font-extrabold font-raleway">
          Submission
        </h4>
        <div className="w-full relative pt-2 flex flex-col h-full text-base leading-[160%] font-semibold">
          {handleLimitCut(
            veiwSubmission?.text ? veiwSubmission?.text : "",
            1850
          )}
        </div>
        <div
          className="self-center w-[240px]"
          onClick={() =>
            setMore(veiwSubmission?.text ? veiwSubmission?.text : "")
          }
        >
          <Button type="button" text="Read more" bg="transparent" />
        </div>
      </div>
      <div className="w-full flex flex-col gap-4 relative flex-1 p-6 bg-primary">
        <div
          onClick={() => setViewSubmission(null)}
          className="absolute right-0 rounded-es-full cursor-pointer bg-white bg-no-repeat bg-[top_.5rem_right_.5rem]  h-[86px] w-[86px] top-0"
          style={{
            backgroundImage: `url("/assets/icons/x.png")`,
          }}
        ></div>
        <h4 className="text-4xl leading-[50.4px] font-extrabold font-raleway">
          Overview
        </h4>
        <p className="flex flex-col pb-6 border-b border-white">
          <span className="text-sm leading-[19.07px] font-semibold">
            Current score
          </span>
          <span>{veiwSubmission?.score}/100</span>
        </p>

        <p className="flex flex-col pb-6 border-b border-white">
          <span className="text-sm leading-[19.07px] font-semibold">
            Judged by
          </span>
          <span>{veiwSubmission?.judges} Judges</span>
        </p>

        <p className="flex flex-col pb-6 border-b border-white">
          <span className="text-sm leading-[19.07px] font-semibold">Rank</span>
          <span>
            {veiwSubmission?.rank}
            {getRankSuffix(veiwSubmission?.rank ? veiwSubmission?.rank : 1)}
          </span>
        </p>
      </div>
    </div>
  );
};
export default SubmissionCard;
