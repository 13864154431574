import React from "react";
import ProfileImage from "../profileImage/ProfileImage";
import { Button } from "../buttons";

interface Props {
  user?: Boolean; // !NOT optional (may be an object)
  guest?: Boolean; // not sure if this is necessary
  winner?: Boolean; // if user is a winner
  text: string;
  userName?: string; // the name of the user !NOT OPTIONAL (could be inside user)
}
const Card: React.FC<Props> = ({ user, winner, userName, text }: Props) => {
  return (
    <div
      className={`${
        winner ? "bg-primary" : "bg-lightPurple"
      } rounded-[22px] max-w-md`}
    >
      <div className={`${!winner && "p-1"}`}>
        <div
          className={`${
            winner
              ? "flex gap-4 p-4 items-center pb-0"
              : "grid grid-cols-1 grid-rows-3 justify-content-center"
          }`}
        >
          <div
            className={`${
              winner
                ? "order-2"
                : "h-[86px] rounded-[18px] col-span-full row-start-1 row-span-2"
            }`}
          >
            {winner ? (
              <div>
                <h4 className="text-secondary font-open_sans text-base leading-[25.6px] font-semibold">
                  Winner
                </h4>
                <p className="font-open_sans text-base leading-[25.6px] font-semibold">
                  {userName ? userName : "John Doe"}
                </p>
              </div>
            ) : (
              <img
                className="h-full w-full object-cover rounded-[18px]"
                src="/assets/images/banner.png"
                alt="banner"
              />
            )}
          </div>
          <div
            className={`${
              winner
                ? "order-1"
                : "ml-4 h-[64px] w-[64px] col-span-full row-start-2 row-span-full self-center"
            }`}
          >
            <ProfileImage />
          </div>
        </div>
        <div className="pl-4">
          {!winner && (
            <div className="mb-4 font-open_sans text-base leading-[25.6px] font-semibold">
              <p className="text-primary m-0">Madonna Model Secondary School</p>
              {user ? <p className="text-navyBlue">Highschool</p> : ""}
            </div>
          )}

          <div className={`${winner ? "m-4" : "mb-4"} w-[178px]`}>
            <Button
              bg="transparent"
              height="38px"
              py="8px"
              type="button"
              text={text}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
