import React from "react";
import SearchIcon from "../../svgicons/SearchIcon";
import { Button } from "../buttons";

interface Props {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  placeholder: string;
  signupEmail?: boolean;
  icon: boolean;
  name: string;
  type: "text" | "search" | "submit" | "email" | "password";
}
const SearchBar: React.FC<Props> = ({
  value,
  setValue,
  placeholder,
  signupEmail,
  icon,
  type,
  name,
}: Props) => {
  return (
    <div className="w-full h-[42px]">
      <div
        className={`${
          signupEmail ? "pl-3 border-[#595959]" : "px-3 border-primary "
        } flex gap-3 py-4 items-center justify-center h-full border rounded-curve`}
      >
        <input
          className={`${
            signupEmail ? "placeholder:text-[#595959]" : "placeholder:text-gray"
          } flex-1 bg-transparent transition ease-in-out duration-300 w-full delay-150 font-quicksand font-medium text-sm leading-[18px] outline-none border-none `}
          type={type}
          placeholder={placeholder}
          value={value}
          name={name}
          onChange={(e) => setValue(e.target.value)}
        />
        {icon && (
          <div>
            {signupEmail ? (
              <Button text="Sign up" type="submit" />
            ) : (
              <SearchIcon />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchBar;
