import React from "react";
import NavBar from "../../reusable/navBar";
import Hero from "../landing";
import { useUserState } from "../../../context/UserContext";
import DashBoard from "../dashboard/DashBoard";

const Layout: React.FC = () => {
  const { isAuthenticated } = useUserState();
  return <>{isAuthenticated ? <DashBoard /> : <Hero />}</>;
};
export default Layout;
