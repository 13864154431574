import React from "react";

interface Props {
  setMore: React.Dispatch<React.SetStateAction<string>>;
  more: string;
}
const MoreSubmissionCard: React.FC<Props> = ({ setMore, more }) => {
  return (
    <div className="font-open_sans relative h-full flex flex-col gap-6  text-base text-primary leading-[25.6px] p-6 font-semibold bg-white">
      <div
        onClick={() => setMore("")}
        className="absolute right-0 cursor-pointer rounded-es-full bg-transparent bg-no-repeat bg-center h-[86px] w-[86px] top-0"
        style={{
          backgroundImage: `url("/assets/icons/x.png")`,
        }}
      ></div>
      <div className="max-w-[720px] w-full">
        <h4 className="text-4xl leading-[50.4px] font-extrabold font-raleway">
          Submission
        </h4>
        <p>{more}</p>
      </div>
    </div>
  );
};

export default MoreSubmissionCard;
