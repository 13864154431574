import React, { LegacyRef } from "react";
import Socials from "./Socials";
import { ALLFOOTERLINKS } from "./constant";
import FooterLinks from "./FooterLinks";

type Props = {
  refs: LegacyRef<HTMLElement>;
};
const Footer: React.FC<Props> = ({ refs }: Props) => {
  return (
    <footer
      ref={refs}
      style={{ backgroundImage: `url("/assets/images/footerbackground.png")` }}
      className="flex text-sm font-roboto p-6 font-normal min-[1127px]:items-center min-[1127px]:justify-center bg-no-repeat bg-contain h-full text-yellow min-[1127px]:h-[293px] bg-blue"
    >
      <div className="flex flex-col min-[1000px]:flex-row gap-[40px] w-full min-[1127px]:gap-60 min-[1127px]:items-center justify-center min-[1127px]:max-w-[76.875%]">
        <div className="max-w-[534px] text-white flex flex-col gap-6">
          <div className="flex flex-col gap-2">
            <div>
              <img src="/assets/images/biz_briger.png" alt="" className="w-[100px]" />
            </div>
            <p>
              BizBriger is your go-to platform for managing and expanding your business. With streamlined tools for product listing, secure payment processing, and customizable features, we make it easy to engage with customers and grow your sales.
            </p>
          </div>
          <Socials />
        </div>
        <div className="grid grid-cols-1 min-[1000px]:grid-cols-3 gap-[60px] min-[1000px]:gap-6">
          {Object.keys(ALLFOOTERLINKS).map((item, i) => {
            return (
              <FooterLinks
                key={i}
                heading={item}
                links={ALLFOOTERLINKS[item]}
              />
            );
          })}
        </div>
      </div>
    </footer>
  );
};
export default Footer;
