import React from "react";
// import { Link } from "react-router-dom";
import { Button } from "../../reusable/buttons";
import { PARTNERSLOGO, HOSTOFFERS, LOREMOFFERS } from "./constant";
import HeroCards from "./HeroCards";
import NewsLetter from "./NewsLetter";
import Footer from "./Footer";
import HeroHeader from "./HeroHeader";

// component references
export const aboutRef = { current: null };
export const serviceRef = { current: null };
export const homeRef = { current: null };
export const contactRef = { current: null };

const Hero: React.FC = () => {
  return (
    <section className="pt-[90px] w-full">
      {/* Hero Image and section start */}
      <section className="flex flex-col h-full container-screen">
        {/* this supposed to have a vh of veiw port best case -> h-[calc(100vh-90px)]
      current figma implementation is 100vh
     but i don't think the designer took that into consideration */}

        <HeroHeader />

        <div
          ref={aboutRef}
          style={{ backgroundImage: `url("/assets/images/grafiti.png")` }}
          className="flex bg-lightPurple bg-no-repeat items-center justify-center bg-contain h-full w-full"
        >
          <div className="flex justify-between gap-8 w-full py-16 px-6 min-[1202px]:max-w-[76.8%] flex-col min-[1202px]:flex-row">
            <div className="max-w-[444px] flex flex-col flex-1 w-fit order-2 min-[1202px]:order-1">
              <h1 className="text-2xl md:text-4xl pb-2 text-primary font-raleway leading-[140%] font-extrabold">
                Shop Smartly with{" "}
                <span className="text-orange">BizBriger</span>
              </h1>
              <p className="text-lg mb-4 text-primary font-open_sans font-semibold leading-[24.51px]">
                Looking for a seamless shopping experience that goes beyond just buying? Discover{" "}
                <span className="text-black font-bold">BizBriger</span> the e-commerce platform that connects you directly with sellers.
                Explore a wide variety of products, enjoy exclusive deals, and chat instantly with sellers to get answers to your questions
                or negotiate deals in real-time
              </p>
            </div>
            <div className="flex-2 max-w-[597.2px] max-h-[320.2px] w-full order-1 self-end">
              <img
                className="w-full h-full"
                src="/assets/images/product_one.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      {/* Hero image and section end */}
      {/* partners section start */}
      <div className="grid grid-cols-3 min-[707px]:grid-cols-6 max-w-[90%]  w-full h-full mx-auto  justify-center gap-8">
        {PARTNERSLOGO.map((item, i) => {
          return (
            <div
              key={item.partner_name}
              className="flex justify-between max-w-[170px] max-h-[170px] h-fit w-fit"
            >
              <img
                className="h-full w-full object-cover"
                src={item.logo}
                alt={item.partner_name}
              />
            </div>
          );
        })}
      </div>
      {/* partners logo section end */}
      {/* host offers start */}
      <section
        className="flex flex-col justify-center items-center gap-12 pt-12 pb-16 bg-lightPurple px-6 md:px-0"
        ref={serviceRef}
      >
        <div className="max-w-[948px] flex flex-col items-center text-center gap-2">
          <h3 className="text-2xl md:text-4xl text-primary font-raleway leading-[140%] font-extrabold">
            Become a Merchant
          </h3>
          <p className="text-sm md:text-lg text-primary font-open_sans font-semibold leading-[24.51px]">
            Let <span className="text-black font-bold">BizBriger</span> help you grow your business and reach a larger audience! By becoming a merchant on our platform, you’ll have access to powerful tools to manage your sales, receive payments, and offer your products or services to users seamlessly. Our streamlined process ensures both you and your customers have an exceptional experience.
          </p>
        </div>
        <div className="flex justify-center items-center gap-6 flex-wrap">
          {HOSTOFFERS.map((item, i) => {
            const { text, title } = item;
            return <HeroCards title={title} text={text} key={i} />;
          })}
        </div>
        <div className="max-w-[566px] flex flex-col items-center text-center gap-4">
          <p className="text-sm md:text-lg text-primary font-open_sans font-semibold leading-[24.51px]">
            BizBriger provides a simple and effective solution to expand your business and reach more customers. Join us today and make your store a success!
          </p>
        </div>
      </section>
      {/* host offers end */}
      {/* lorem offers start */}
      <section
        style={{
          backgroundImage: `url("/assets/images/transparentbackground.png")`,
        }}
        className="flex flex-col justify-center items-center gap-12 pt-12 pb-16 bg-no-repeat px-6 md:px-0"
      >
        <div className="max-w-[948px] flex flex-col items-center text-center gap-2">
          <h3 className="text-2xl md:text-4xl text-primary font-raleway leading-[140%] font-extrabold">
            Discover More, Shop Better
          </h3>
          {/*<p className="text-sm md:text-lg text-primary font-open_sans font-semibold leading-[24.51px]">*/}
          {/*  Are you ready to showcase your talent, skills and creativity to the*/}
          {/*  world ?*/}
          {/*</p>*/}
        </div>
        <div className="flex gap-6 flex-wrap justify-center">
          {LOREMOFFERS.map((item, i) => {
            const { icon, text, title } = item;
            return <HeroCards title={title} text={text} icon={icon} key={i} />;
          })}
        </div>
        <div className="max-w-[566px] flex flex-col items-center text-center gap-4">
          <p className="text-sm md:text-lg text-primary font-open_sans font-semibold leading-[24.51px]">
            Join <span className="text-black font-bold">BizBriger</span> today and redefine your shopping experience! Find everything you need and more, all in one place.
          </p>
          {/* navigate links to https://www.google.com */}
          {/*<a href="https://app.contestify.io/" target="_blanck">*/}
          {/*  <Button text="Compete Now" type="button" />*/}
          {/*</a>*/}
        </div>
      </section>
      <NewsLetter />
      <Footer refs={contactRef} />
    </section>
  );
};

export default Hero;
