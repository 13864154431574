import React, { useState } from "react";
import { LOGGEDINITEMS, NOTLOGGEDINITEMS } from "./constant";
import { NavLink } from "react-router-dom";

type Props = {
  mobile: boolean;
};

const NavItems: React.FC<Props> = ({ mobile }: Props) => {
  const [active, setActive] = useState("Home");
  const scrollToSection = (elementRef: any, pathName: string) => {
    window.scrollTo({
      top: elementRef?.current?.offsetTop - 90,
      behavior: "smooth",
    });
    setActive(pathName);
  };

  return (
    <div
      className={`${
        mobile ? "flex-col gap-6" : " justify-between max-h-[200px] gap-4"
      }  h-full   text-primary font-normal flex w-full`}
    >
      {NOTLOGGEDINITEMS.map((link, i) => {
        const { path, pathName } = link;
        return (
          <div
            key={i}
            className={`
            flex h-full justify-center w-fit transition duration-600 delay-75 ease-in-out
              ${
                pathName === active
                  ? "border-b border-secondary font-bold text-secondary duration-600 delay-75 ease-in-out"
                  : "duration-600 delay-75 ease-in-out"
              }

            `}
            onClick={() => scrollToSection(path, pathName)}
          >
            <span className="text-center self-start">{pathName}</span>
          </div>
        );
      })}
    </div>
  );
};

export default NavItems;
