export interface requirements {
  requirements: string[];
}

export const mockInfo: requirements = {
  requirements: [
    "Understand your assignment: What is the goal of this essay? What is the length and deadline of the assignment? Is there anything you need to clarify with your teacher or professor?",
    "Define a topic: If you’re allowed to choose your own topic, try to pick something that you already know a bit about and that will hold your interest.",
    "Do your research: Read primary and secondary sources and take notes to help you work out your position and angle on the topic. You’ll use these as evidence for your points.",
    "Come up with a thesis: The thesis is the central point or argument that you want to make. A clear thesis is essential for a focused essay—you should keep referring back to it as you write.",
    "Create an outline: Map out the rough structure of your essay in an outline. This makes it easier to start writing and keeps you on track as you go.",
  ],
};
