/* eslint-disable no-restricted-globals */
import React, { useState } from "react";
import { Button } from "../../reusable/buttons";
import InputBar from "../../reusable/searchBar";
import { Link, useNavigate, useNavigation } from "react-router-dom";

const HostSignUp: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [hostName, setHostName] = useState<string>("");
  const [hostType, setHostType] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const navigate = useNavigate();
  const location = useNavigation();

  console.log(location);
  return (
    <div className="w-full fixed z-[99] right-0 left-0 top-0 bottom-0 backdrop-blur-[10px] bg-white/80 h-screen flex">
      <section className=" box-border rounded-3xl max-w-[1280px] max-h-[731px] h-full grid grid-cols-2 w-full m-auto">
        <div className="bg-white max-w-[640px] rounded-s-[22px] gap-12 w-full h-full flex flex-col items-center justify-center">
          <div className="flex flex-col items-center gap-4">
            <img src="/assets/images/logo.png" alt="contestify logo" />
            <h3 className="font-raleway font-extrabold text-primary text-[20px] leading-7">
              Create a host account
            </h3>
          </div>

          <div className="max-w-[56.25%] w-full flex flex-col gap-7">
            <div className="flex w-full gap-6">
              <div className="flex-1">
                <Button
                  bg="transparent"
                  border_gray
                  icon="/assets/icons/Google.svg"
                  type="button"
                />
              </div>
              <div className="flex-1">
                <Button
                  bg="transparent"
                  border_gray
                  icon="/assets/icons/Apple.svg"
                  type="button"
                />
              </div>
            </div>
            <div className="relative font-quicksand font-normal text-sm text-primary">
              <hr className="hr-content" />
            </div>
            <InputBar
              icon={false}
              placeholder="Country"
              value={country}
              setValue={setCountry}
              type="text"
              name="country"
            />
            <InputBar
              icon={false}
              placeholder="Host name"
              value={hostName}
              setValue={setHostName}
              type="text"
              name="hostName"
            />
            <InputBar
              type="text"
              name="hostType"
              icon={false}
              placeholder="host type"
              value={hostType}
              setValue={setHostType}
            />
            <InputBar
              icon={false}
              placeholder="Email address"
              value={email}
              setValue={setEmail}
              type="email"
              name="email"
            />
            <InputBar
              type="password"
              name="password"
              icon={false}
              placeholder="Password"
              value={password}
              setValue={setPassword}
            />
          </div>
          <div className="max-w-[56.25%] w-full shadow-lg shadow-indigo-500/50 rounded-curve">
            <Button type="submit" />
          </div>
          <p className="font-semibold text-sm leading-[19.07px] font-open_sans">
            Already have an account?{" "}
            <Link
              to={"/login"}
              className="text-secondary hover:underline font-bold "
            >
              Login
            </Link>
          </p>
        </div>
        <div
          style={{
            backgroundImage: `url("/assets/images/LoginBackground.png")`,
          }}
          className="bg-no-repeat bg-cover flex justify-center flex-col w-full h-full relative rounded-e-[22px]"
        >
          <img
            className="absolute top-5 right-[29.7px]"
            src="/assets/icons/x.png"
            alt="close icon"
            onClick={() =>
              navigate("/", {
                replace: true,
              })
            }
          />
          <div className="max-w-[381px] flex flex-col items-start gap-6 bg-white p-6 rounded-e-[22px] border-l-2 border-dashed">
            <p className=" font-open_sans font-semibold text-lg leading-[25px] text-primary">
              Discover and participate in thousands of competitions from around
              the world, all in one place. Join our platform to register and
              submit for local and international competitions, and boost your
              chances of winning.
            </p>
            <Link to={"/signup"}>
              <Button
                type="button"
                text="Join as Competitor"
                bg="transparent"
              />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
};
export default HostSignUp;
