import React from "react";

const HeroHeader: React.FC = () => {
  return (
    <div className="md:max-h-[396.24px] h-full flex md:items-center flex-col md:flex-row gap-4 px-6 md:px-0">
      <div className="max-w-[240px] md:max-w-[360px] md:max-h-[268.24px] w-fit h-fit">
        <img
          className="w-full h-full object-cover"
          src="/assets/images/logo_primary.png"
          alt=""
        />
      </div>

      <div className="flex flex-col gap-2 self-center pb-6 md:p-0">
        <p className="text-sm md:text-2xl font-pacifico text-light_orange font-normal leading-[42.14px]">
          Browse. Shop. Enjoy.
        </p>
        <h2 className="text-2xl md:text-4xl text-primary font-raleway leading-[140%] font-extrabold">
          Discover Deals Tailored for You
        </h2>
        <blockquote className="text-sm md:text-lg text-primary font-open_sans font-semibold leading-[24.51px]">
          {/* eslint-disable-next-line no-octal-escape*/}
          <p className="before:content-['\201C'] after:content-['\201D'] inline md:block">
            Shopping made easy with exclusive offers and fast delivery.
          </p>
        </blockquote>
      </div>
    </div>
  );
};

export default HeroHeader;
