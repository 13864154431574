import React from "react";

interface Props {
  active: boolean;
}
const NextIcon: React.FC<Props> = ({ active }) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 12.5L10 8.5L6 4.5"
        stroke={`${active ? "#BF6B04" : "#595959"}`}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export default NextIcon;
