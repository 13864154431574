import React from "react";
type Props = {
  userImage?: string; // user image
};
const ProfileImage: React.FC<Props> = ({ userImage }: Props) => {
  return (
    <div className="w-full h-full max-w-[100px] max-h-[100px]">
      <img
        className="w-full h-full object-cover rounded-full"
        src={userImage ? userImage : "/assets/images/profileimage.png"}
        alt=""
      />
    </div>
  );
};
export default ProfileImage;
