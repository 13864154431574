import React, { useState } from "react";
import { JUDGEOVERVIEW, JUDGETAB, BACKGROUND } from "./constant";
import OverviewCard from "../../reusable/card/OverviewCard";
import Tabs from "../../reusable/tabs/Tabs";
import TabView from "./TabView";
import { hostPost } from "../../reusable/competition_card/constant";
import CardModal from "../../reusable/competition_card/CardModal";

const JudgeDashboard: React.FC = () => {
  const [openCard, setOpenCard] = useState<undefined | hostPost>(undefined);
  const [selected, setSelected] = useState<string>(
    Object.keys(JUDGETAB)[0] || ""
  );
  return (
    <div className="flex relative  flex-col min-vh h-full max-vw w-full left-[328px] rounded-[11px] gap-2 text-primary font-open_sans font-normal text-sm leading-[22.4px]">
      {openCard && (
        <CardModal setOpenCard={setOpenCard} openCard={openCard} ongoing />
      )}
      <div className="bg-white w-full p-6 flex flex-col gap-3">
        <h5 className="font-raleway font-bold text-2xl leading-[140%]">
          Overview
        </h5>
        <div className="flex gap-4">
          {JUDGEOVERVIEW.map((item, i) => {
            const { title, details } = item;
            return (
              <OverviewCard
                title={title}
                details={details}
                bg={BACKGROUND[i]}
                key={title}
              />
            );
          })}
        </div>
      </div>
      <div className="bg-white flex-1 flex flex-col gap-4 p-6 w-full h-full">
        <h5 className="font-raleway font-bold text-2xl leading-[140%]">
          My competitions
        </h5>
        <div className="flex gap-4">
          {Object.keys(JUDGETAB).map((title, i) => {
            return (
              <Tabs
                title={title}
                total={JUDGETAB[title].length}
                selected={selected === title}
                setSelected={setSelected}
                key={title}
              />
            );
          })}
        </div>
        {Object.keys(JUDGETAB).map((title, i) => {
          return (
            <TabView
              setOpenCard={setOpenCard}
              selected={selected === title}
              title={title}
              details={JUDGETAB[title]}
              key={title}
            />
          );
        })}
      </div>
    </div>
  );
};

export default JudgeDashboard;
