import React from "react";

interface Props {
  url: string;
  school: string;
}

const HostCard: React.FC<Props> = ({ url, school }: Props) => {
  return (
    <div className="flex p-3 justify-between items-center bg-white rounded-[22px] shadow-light">
      <div className="flex items-center gap-4 w-ful peer">
        <div
          className="w-8 h-8 rounded-full bg-no-repeat bg-center bg-gray"
          style={{ backgroundImage: `url(${url})` }}
        ></div>
        <div className="flex-1 font-open_sans font-semibold">
          <h6 className=" text-xs leading-[16.8px] text-orange">Host</h6>
          <p className="text-base leading-[22.4px] text-primary hover:underline">
            {school}
          </p>
        </div>
      </div>
      <div
        className="hidden bg-center bg-no-repeat w-2 h-3 peer-hover:block"
        style={{ backgroundImage: `url("/assets/icons/arrow-right.png")` }}
      ></div>
    </div>
  );
};

export default HostCard;
