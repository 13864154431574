import React from "react";
import { hostPost, handleDate, handleLimitCut } from "./constant";
import HostCard from "./HostCard";

interface Props {
  host?: boolean;
  user?: boolean;
  post: hostPost;
  setOpenCard: React.Dispatch<React.SetStateAction<undefined | hostPost>>;
  openCard: undefined | hostPost;
}

const CompetitionCard: React.FC<Props> = ({
  host,
  user,
  post,
  setOpenCard,
  openCard,
}: Props) => {
  return (
    <div
      className="flex h-full text-primary p-5 cursor-pointer"
      onClick={() => setOpenCard(openCard === undefined ? post : undefined)}
    >
      {user && (
        <div className="max-w-[310px] relative shadow-lg h-full w-full py-5 px-4 flex flex-col justify-between gap-8 border-[3px] border-transparent hover:border-light_orange rounded-[22px] bg-white">
          <p className=" font-open_sans font-semibold text-xl leading-8">
            {handleLimitCut(post.description, 100)}
          </p>
          <p className=" self-center flex items-center gap-3">
            <span>{handleDate(post.datetime)}</span>
            <span className="w-1 h-1 rounded-full bg-blue"></span>
            <span>
              {post.attendees}{" "}
              {post.attendees > 1 ? "participants" : "participant"}
            </span>
          </p>
          <HostCard school={post.school} url={post.imageUrl} />
          <div className="backdrop absolute "></div>
        </div>
      )}
      {host && <div></div>}
    </div>
  );
};

export default CompetitionCard;
