import React from "react";

interface Props {
  text?: string; // the text content
  bg?: string; //background type
  height?: string; // height of button
  py?: string; // padding top and bottom
  icon?: string;
  border_gray?: boolean;
  type: "submit" | "reset" | "button" | undefined;
}
const Button: React.FC<Props> = ({
  text,
  bg,
  height,
  py,
  border_gray,
  icon,
  type,
}: Props) => {
  return (
    <div>
      <button
        type={type}
        style={{
          height: `${height ? height : "48px"}`,
          paddingBlock: `${py ? py : "14px"}`,
        }}
        className={` ${
          bg === "white"
            ? "bg-btnGray"
            : bg === "transparent"
            ? "bg-transparent"
            : "bg-secondary text-white border-none"
        }
        w-full
        ${
          bg === "transparent" &&
          !border_gray &&
          "border border-secondary text-secondary"
        }
        ${
          bg === "transparent" &&
          border_gray &&
          "border border-primary text-secondary"
        }
        ${bg === "white" && "text-secondary border-none"}
        px-6 active:scale-95 transition ease-in duration-300 rounded-curve outline-none flex items-center justify-center font-quicksand font-bold text-base leading-5
      `}
      >
        {icon ? (
          <div className="flex items-center justify-center">
            <img src={icon} alt="icon" />
          </div>
        ) : (
          <span className="">{text ? text : "Register"}</span>
        )}
      </button>
    </div>
  );
};

export default Button;
