import React from "react";
import { UserProvider } from "./context/UserContext";
import { RouterProvider } from "react-router-dom";
import { router } from "./router/AppRoute";

function App() {
  return (
    <div className="App">
      <UserProvider>
        <RouterProvider router={router} />
      </UserProvider>
    </div>
  );
}

export default App;
