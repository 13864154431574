import React from "react";
import { SOCIALICONS } from "./constant";

const Socials: React.FC = () => {
  return (
    <div className="flex gap-6 items-center">
      {SOCIALICONS.map((item, i) => {
        const { logo, link, name } = item;
        return (
          <a
            href={link}
            className="flex items-center justify-center h-10 w-10 bg-transparentWhite border border-transparentWhite rounded"
            key={i}
          >
            <img src={logo} alt={name} />
          </a>
        );
      })}
    </div>
  );
};
export default Socials;
