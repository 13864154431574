import React from "react";
import { mockInfo, requirements } from "./constant";

interface Props {
  setMoreInfo: React.Dispatch<React.SetStateAction<requirements | null>>;
}
const MoreRequirements: React.FC<Props> = ({ setMoreInfo }) => {
  return (
    <div className="font-open_sans h-full flex flex-col gap-6  text-base text-primary leading-[25.6px] p-6 font-semibold bg-white">
      <div className="max-w-[720px] w-full">
        <div
          onClick={() => setMoreInfo(null)}
          className="absolute right-0 rounded-es-full bg-transparent bg-no-repeat bg-center h-[86px] w-[86px] top-0"
          style={{
            backgroundImage: `url("/assets/icons/x.png")`,
          }}
        ></div>
        <h4 className="text-4xl leading-[50.4px] font-extrabold font-raleway">
          Description
        </h4>
        <p>
          Shopping is the favourite pastime for most of the young people.
          <br /> Why do you think is that? <br />
          Do you think they should be encouraged to do some other useful
          activities?
        </p>
      </div>
      <h4 className="text-4xl leading-[50.4px] font-extrabold font-raleway">
        Requirements
      </h4>
      <ol className="max-w-[720px] w-full">
        {mockInfo.requirements.map((item, i) => {
          let idx = i + 1;
          return (
            <li key={idx} className="grid-requirement gap-3">
              <span>{idx}.</span>
              {item}
            </li>
          );
        })}
      </ol>
    </div>
  );
};

export default MoreRequirements;
