import React from "react";
import { Outlet } from "react-router-dom";
import { BackButton, Button, LogoutButton } from "../../reusable/buttons";
import JudgeSwitch from "../sidemenu/JudgeSwitch";
import SideBarMenu from "../sidemenu/SideBarMenu";
import { ProfileCard } from "../../reusable/card";

interface Props {
  host: boolean;
  user: boolean;
}
const ProfilePage: React.FC<Props> = ({ host, user }) => {
  return (
    <div className="pt-[90px] h-full overflow-x-hidden flex gap-2 bg-lightGray pr-2">
      <div className="max-w-[320px] min-vh fixed w-full p-6 flex flex-col gap-6 bg-white">
        <BackButton from="/" />
        <ProfileCard host={host} user={user} />
        {user && <JudgeSwitch />}
        {host && <Button type="button" text="Host Competitions" />}

        {(host || user) && <SideBarMenu host={host} />}
        {(host || user) && <LogoutButton />}
      </div>
      <Outlet />
    </div>
  );
};

export default ProfilePage;
