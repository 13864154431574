import React from "react";

interface Props {
  total: number;
  title: string;
  selected: boolean;
  setSelected: React.Dispatch<React.SetStateAction<string>>;
}
const Tabs: React.FC<Props> = ({ title, selected, total, setSelected }) => {
  return (
    <div
      className={`
      ${
        selected
          ? " bg-gradient_orange text-white shadow-gradient"
          : " bg-gray_2 text-primary"
      }
    p-2 max-w-[103px] cursor-pointer max-h-[92px] w-full h-full flex flex-col rounded-[6px] 
    font-quicksand font-semibold text-[32px] leading-[40px] border border-transparent`}
      onClick={() => setSelected(title)}
    >
      <span>{total}</span>
      <span className="text-sm leading-[18px]">{title}</span>
    </div>
  );
};

export default Tabs;
