import React from "react";
import { Link } from "react-router-dom";
import RightNavigation from "./RightNavigation";

const NavBar: React.FC = () => {
  return (
    <div className="h-[90px] z-50 fixed flex bg-lightGray items-center w-full m-auto justify-center">
      <nav className="flex items-center h-full justify-between w-full max-w-[93.7%]">
        <div className="flex justify-between w-full">
          <div className="self-center">
            <Link to="/">
              <img className="w-[100px]" src="/assets/images/biz_briger.png" alt="" />
            </Link>
          </div>
          <RightNavigation />
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
