import React from "react";

interface Props {
  title: string;
  details: string;
  bg: string;
}
const OverviewCard: React.FC<Props> = ({ title, details, bg }) => {
  return (
    <div
      style={{ backgroundColor: `${bg}` }}
      className={`px-4 py-3 flex flex-col rounded-[6px] gap-2 text-primary font-quicksand font-semibold text-2xl leading-[30px]`}
    >
      <p className="text-sm leading-[17.5px]">{title}</p>
      <p>{details}</p>
    </div>
  );
};

export default OverviewCard;
