import React, { useState } from "react";
import { Card } from "../card";
import CreatedAt from "../created_at/CreatedAt";
import Tags from "../tags/Tags";
import ShareComponent from "../share/ShareComponent";
import Requirements from "../requirements/Requirments";
import Register from "../register/Register";
import { hostPost, submission } from "./constant";
import { requirements } from "../requirements/constant";
import MoreRequirements from "../requirements/MoreRquirements";
import CompetitionForm from "../competion_form/CompetitionForm";
import CompletedCard from "./CompletedCard";
import SubmissionCard from "./SubmissionCard";
import MoreSubmissionCard from "./MoreSubmissionCard";

interface Props {
  setOpenCard: React.Dispatch<React.SetStateAction<undefined | hostPost>>;
  openCard: undefined | hostPost; //post informations
  ongoing?: boolean;
}

const CardModal: React.FC<Props> = ({ setOpenCard, openCard, ongoing }) => {
  const [moreInfo, setMoreInfo] = useState<null | requirements>(null);
  const [numWords, setNumWords] = useState<number>(0); // words for submition
  const [moreSubmission, setMoreSubmission] = useState<string>("");
  const [veiwSubmission, setVeiwSubmission] = useState<submission | null>(null);

  const handleSCloseModal = (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as Element;
    if (target.classList.contains("modal-backdrop")) {
      setOpenCard(undefined);
    }
  };

  return (
    <div
      className={`${
        openCard ? " translate-y-0" : " translate-y-full"
      } fixed transition delay-[45] ease-in-out duration-200 z-[100] modal-backdrop bg-transparentDark flex items-center justify-center right-0 left-0 bottom-0 top-0`}
      onClick={handleSCloseModal}
    >
      <div className="bg-white relative grid grid-cols-3 max-h-[762px] overflow-hidden max-w-[1184px] w-full rounded-[22px] ">
        <div
          onClick={() => setOpenCard(undefined)}
          className=" absolute right-0 rounded-es-full cursor-pointer bg-white bg-no-repeat bg-[top_.5rem_right_.5rem]  h-[86px] w-[86px] top-0"
          style={{
            backgroundImage: `url("/assets/icons/x.png")`,
          }}
        ></div>
        <div className="flex flex-col p-6 gap-6">
          {/* expects profile image and school */}
          <Card text="Veiw Profile" />
          {/* expects post creation */}
          <CreatedAt created_at={"12, Apr, 2012"} />
          {/* expects tags */}
          <Tags />
          <ShareComponent />
        </div>
        {/* expects requirements */}
        <Requirements setMoreInfo={setMoreInfo} moreInfo={moreInfo} />
        {/* expects any other requiremnets for registering */}

        {!openCard?.completed && (
          <Register
            numWords={numWords}
            setNumWords={setNumWords}
            ongoing={ongoing}
          />
        )}
        {openCard?.completed && (
          <CompletedCard setViewSubmission={setVeiwSubmission} />
        )}
        {
          <div
            className={`${
              moreInfo ? "traslate-y-0" : "translate-y-full"
            } absolute transition delay-[20] duration-300 ease-in-out right-0 left-0 top-0 bottom-0`}
          >
            <MoreRequirements setMoreInfo={setMoreInfo} />
          </div>
        }
        {
          <div
            className={`${
              numWords ? "traslate-y-0" : "translate-y-full"
            } absolute transition delay-[20] duration-300 ease-in-out right-0 left-0 top-0 bottom-0`}
          >
            <CompetitionForm
              setOpenCard={setOpenCard}
              numWords={numWords}
              setNumWords={setNumWords}
            />
          </div>
        }
        {
          <div
            className={`${
              veiwSubmission ? "traslate-y-0" : "translate-y-full"
            } absolute transition delay-[20] duration-300 ease-in-out right-0 left-0 top-0 bottom-0`}
          >
            <SubmissionCard
              veiwSubmission={veiwSubmission}
              setViewSubmission={setVeiwSubmission}
              setMore={setMoreSubmission}
            />
          </div>
        }
        {
          <div
            className={`${
              moreSubmission ? "traslate-y-0" : "translate-y-full"
            } absolute transition delay-[20] duration-300 ease-in-out right-0 left-0 top-0 bottom-0`}
          >
            <MoreSubmissionCard
              setMore={setMoreSubmission}
              more={moreSubmission}
            />
          </div>
        }
      </div>
    </div>
  );
};

export default CardModal;
