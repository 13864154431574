import React from "react";
import { NavigateFunction } from "react-router-dom";

var UserStateContext = React.createContext<any>(null);
var UserDispatchContext = React.createContext<any>(null);

function userReducer(state?: any, action?: any): void {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

/* Provider Context*/

function UserProvider({ children }: any): JSX.Element {
  var [state, dispatch] = React.useReducer<(state: any, action: any) => any>(
    userReducer,
    {
      isAuthenticated: !!localStorage.getItem("id_token"),
    }
  );
  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

type LoginProps = {
  dispatch: (state?: any, action?: any) => void;
  navigate: NavigateFunction;
};

// Temporary login
function loginUser({ dispatch, navigate }: LoginProps) {
  setTimeout(() => {
    localStorage.setItem("id_token", JSON.stringify(1));
    dispatch({ type: "LOGIN_SUCCESS" });
    navigate("/");
  }, 2000);
}

function signOut(dispatch: (state?: any, action?: any) => void, history: any) {
  localStorage.removeItem("id_token");
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  history.push("/login");
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut };

// ###########################################################
