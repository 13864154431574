import React from "react";
import { MENULINKS } from "./constant";
import SideLInk from "./SideLink";
interface Props {
  host: boolean;
}
const SideBarMenu: React.FC<Props> = ({ host }) => {
  return (
    <div className="flex flex-1 flex-col max-h-[vh] h-full text-primary gap-6 rounded-[11px] border p-4 pb-0 border-[#E9EBEE] font-semibold font-open_sans text-sm">
      <h6 className="text-base leading-[160%]">Menu</h6>
      <div className="sidelink-items h-full">
        {MENULINKS.map((item, i) => {
          const { name, link } = item;
          if (name === "Results" && !host) {
            return "";
          } else {
            return <SideLInk name={name} link={link} key={`${name}${i}`} />;
          }
        })}
      </div>
    </div>
  );
};

export default SideBarMenu;
