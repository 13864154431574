import React, { useState } from "react";
import SignUpbar from "../../reusable/searchBar"; // searchBar

const NewsLetter: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  console.log(email);
  return (
    <div
      style={{
        backgroundImage: `url("/assets/images/signupdesign.png")`,
      }}
      className="bg-orange bg-[right_-8rem_bottom_-4rem] flex flex-col min-[970px]:flex-row justify-between h-[508px] min-[970px]:h-[408px] min-[970px]:items-center px-6 md:px-16 bg-no-repeat"
    >
      <div className="max-w-[566px] pb-16 min-[970px]:pb-0 w-fit flex flex-col gap-2 order-2 min-[970px]:order-1">
        <h5 className="text-2xl md:text-4xl leading-[140%] max-w-[260px] md:max-w-full font-raleway font-black text-secondary">
          Stay Ahead of the Game
        </h5>
        <p className="text-sm md:text-lg text-primary font-open_sans font-semibold leading-[24.51px]">
          Sign up for BizBriger’s email updates and never miss out on the latest deals, exclusive offers, and new arrivals. Get all the best products and services delivered straight to your inbox.
        </p>
        <SignUpbar
          placeholder="Email address"
          value={email}
          setValue={setEmail}
          name="newsletter"
          type="email"
          icon
          signupEmail
        />
      </div>
      <div className="w-[160px] h-[160px] min-[970px]:w-[281px] min-[970px]:h-[281px]  order-1 self-end min-[970px]:self-center">
        <img
          src="/assets/images/bro.svg"
          alt="confetti"
          className="w-full h-full object-contain"
        />
      </div>
    </div>
  );
};
export default NewsLetter;
