import React from "react";
import { hostPost } from "../../reusable/competition_card/constant";
import InformationCard from "../../reusable/card/InformationCard";

interface Props {
  setOpenCard: React.Dispatch<React.SetStateAction<undefined | hostPost>>;
  selected: boolean;
  title: string;
  details: hostPost[];
}
const TabView: React.FC<Props> = ({
  title,
  details,
  selected,
  setOpenCard,
}) => {
  return (
    <div
      className={`${
        selected ? "flex" : "hidden"
      } flex-col rounded-[6px] gap-4 text-primary font-raleway font-extrabold text-xl`}
    >
      <h5 className=" capitalize">{title}</h5>
      <div className="flex flex-wrap gap-6">
        {details
          ? details.map((post, i) => {
              return (
                <InformationCard
                  post={post}
                  key={post?.id}
                  setOpenCard={setOpenCard}
                />
              );
            })
          : "no information"}
      </div>
    </div>
  );
};

export default TabView;
