import { LegacyRef } from "react";
import {
  serviceRef,
  aboutRef,
  contactRef,
  homeRef,
} from "../../pageComponents/landing/Hero";

type hashRef = { path: LegacyRef<HTMLElement>; pathName: string };
type endpoint = { path: string; pathName: string };
export const NOTLOGGEDINITEMS: hashRef[] = [
  {
    path: homeRef,
    pathName: "Home",
  },
  {
    path: aboutRef,
    pathName: "About us",
  },
  {
    path: serviceRef,
    pathName: "Services",
  },
  {
    path: contactRef,
    pathName: "Contact Us",
  },
];

export const LOGGEDINITEMS: endpoint[] = [
  {
    path: "/",
    pathName: "Home",
  },
  {
    path: "/explore",
    pathName: "Explore",
  },
  {
    path: "/contact_us",
    pathName: "Contact Us",
  },
];
