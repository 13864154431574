import React from "react";
import { footerLinks } from "./constant";
import { Link } from "react-router-dom";

type Props = {
  heading: string;
  links: footerLinks[];
};
const FooterLinks: React.FC<Props> = ({ links, heading }: Props) => {
  return (
    <div className="flex flex-col gap-10 text-white">
      <h5 className="capitalize font-semibold text-lg leading-[21.09px]">
        {heading}
      </h5>
      <div className="flex flex-col leading-[16.41px] gap-5 min-w-fit">
        {links?.map((item, i) => {
          const { icon, text, link } = item;
          return (
            <div className="flex gap-2" key={i}>
              {link ? (
                <Link to={link}>
                  {icon && (
                    <div className="w-6 h-6">
                      <img className="w-full h-full" src={icon} alt="icon" />
                    </div>
                  )}
                  <p className="w-full">{text}</p>
                </Link>
              ) : (
                <div className="flex gap-3 items-center justify-center">
                  {icon && (
                    <div className="w-6 h-6">
                      <img className=" w-full h-full" src={icon} alt="icon" />
                    </div>
                  )}
                  <p className="w-full">{text}</p>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default FooterLinks;
