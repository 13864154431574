import React from "react";
import ProfileImage from "../profileImage/ProfileImage";
import { Button } from "../buttons";

interface Props {
  user: Boolean; // !NOT optional (may be an object)
  host: boolean;
  userName?: string; // the name of the user !NOT OPTIONAL (could be inside user)
}
const ProfileCard: React.FC<Props> = ({ user, host, userName }: Props) => {
  return (
    <div className=" bg-lightGray rounded-[22px] max-w-md">
      <div className="p-1">
        <div className="grid grid-cols-1 grid-rows-3 justify-content-center">
          <div className="h-[86px] rounded-[18px] col-span-full row-start-1 row-span-2">
            <img
              className="h-full w-full object-cover rounded-[18px]"
              src="/assets/images/banner.png"
              alt="banner"
            />
          </div>
          <div className="ml-4 mb-0 h-[64px] w-[64px] col-span-full row-start-2 row-span-full self-center">
            <ProfileImage />
          </div>
        </div>

        <div className="mb-4 px-4 font-open_sans text-base leading-[25.6px] font-semibold">
          <p className="text-primary m-0">
            {host || (!host && !user)
              ? "Madonna University"
              : userName
              ? userName
              : "John Doe"}
          </p>
          {(host || (!host && !user)) && (
            <p className="text-navyBlue">School</p>
          )}

          {(user || host) && (
            <div className="mt-4 w-[178px]">
              <Button
                bg="transparent"
                height="38px"
                py="8px"
                type="button"
                text={"Edit Profile"}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
