/* eslint-disable no-restricted-globals */
import React, { useState } from "react";
import { Button } from "../../reusable/buttons";
import SearchBar from "../../reusable/searchBar";
import { Link, useNavigate } from "react-router-dom";

const SignUpComponent: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const navigate = useNavigate();

  return (
    <div className="w-full fixed z-[99] right-0 left-0 top-0 bottom-0 backdrop-blur-[10px] bg-white/80 h-screen flex">
      <section className=" box-border rounded-3xl max-w-[1280px] max-h-[731px] h-full grid grid-cols-2 w-full m-auto">
        <div className="bg-white max-w-[640px] rounded-s-[22px] gap-12 w-full h-full flex flex-col items-center justify-center">
          <div className="flex flex-col items-center gap-4">
            <img src="/assets/images/logo.png" alt="contestify logo" />
            <h3 className="font-raleway font-extrabold text-primary text-[20px] leading-7">
              Create a free account
            </h3>
          </div>

          <div className="max-w-[56.25%] w-full flex flex-col gap-7">
            <div className="flex w-full gap-6">
              <div className="flex-1">
                <Button
                  bg="transparent"
                  border_gray
                  icon="/assets/icons/Google.svg"
                  type="button"
                />
              </div>
              <div className="flex-1">
                <Button
                  bg="transparent"
                  border_gray
                  icon="/assets/icons/Apple.svg"
                  type="button"
                />
              </div>
            </div>
            <div className="relative font-quicksand font-normal text-sm text-primary">
              <hr className="hr-content" />
            </div>
            <SearchBar
              icon={false}
              placeholder="Country"
              value={country}
              setValue={setCountry}
              type="text"
              name="country"
            />

            <div className="w-full flex gap-7">
              <SearchBar
                icon={false}
                placeholder="first name"
                value={firstName}
                setValue={setFirstName}
                type="text"
                name="firstName"
              />
              <SearchBar
                type="text"
                name="lastName"
                icon={false}
                placeholder="last name"
                value={lastName}
                setValue={setLastName}
              />
            </div>
            <SearchBar
              icon={false}
              placeholder="Email address"
              value={email}
              setValue={setEmail}
              type="email"
              name="email"
            />
            <SearchBar
              type="password"
              name="password"
              icon={false}
              placeholder="Password"
              value={password}
              setValue={setPassword}
            />
          </div>
          <div className="max-w-[56.25%] w-full shadow-lg shadow-indigo-500/50 rounded-curve">
            <Button type="submit" />
          </div>
          <p className="font-semibold text-sm leading-[19.07px] font-open_sans">
            Already have an account?{" "}
            <Link
              to={"/login"}
              className="text-secondary hover:underline font-bold "
            >
              Login
            </Link>
          </p>
        </div>
        <div
          style={{
            backgroundImage: `url("/assets/images/LoginBackground.png")`,
          }}
          className="bg-no-repeat bg-cover flex justify-center flex-col w-full h-full relative rounded-e-[22px]"
        >
          <img
            className="absolute top-5 right-[29.7px]"
            src="/assets/icons/x.png"
            alt="close icon"
            onClick={() =>
              navigate("/", {
                replace: true,
              })
            }
          />
          <div className="max-w-[381px] flex flex-col items-start gap-6 bg-white p-6 rounded-e-[22px] border-l-2 border-dashed">
            <p className=" font-open_sans font-semibold text-lg leading-[25px] text-primary">
              Contestify is the #1 hassle free platform to host your
              competition. Reach the right audience, streamline operations and
              access analytic tools for free.
            </p>
            <Link to={"/host_signup"}>
              <Button type="button" text="Join as Host" bg="transparent" />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
};
export default SignUpComponent;
