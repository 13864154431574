import React from "react";

interface Props {
  created_at: string;
}
const CreatedAt: React.FC<Props> = ({ created_at }) => {
  return (
    <div className="px-4 py-3 flex flex-col rounded-[11px] gap-2 bg-lightGray text-primary font-open_sans font-normal text-sm leading-[22.4px]">
      <h6 className="">Created on:</h6>
      <p className="font-semibold">{created_at}</p>
    </div>
  );
};

export default CreatedAt;
