import React from "react";

const SearchIcon: React.FC = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.66732 12.6667C10.6128 12.6667 13.0007 10.2789 13.0007 7.33333C13.0007 4.38781 10.6128 2 7.66732 2C4.7218 2 2.33398 4.38781 2.33398 7.33333C2.33398 10.2789 4.7218 12.6667 7.66732 12.6667Z"
        stroke="#595959"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3336 14.0001L11.4336 11.1001"
        stroke="#595959"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SearchIcon;
