import React, { useState } from "react";
import { Button } from "../buttons";
import { Card } from "../card";
import { submission } from "./constant";
import { SUBMISSION } from "./constant";

interface Props {
  setViewSubmission: React.Dispatch<React.SetStateAction<submission | null>>;
}
const CompletedCard: React.FC<Props> = ({ setViewSubmission }) => {
  const [viewWinnerSubmission, setViewWinnerSubmission] =
    useState<boolean>(false);

  return (
    <div className="bg-white h-full p-6 flex flex-col gap-6 text-base font-open_sans text-primary leading-[25.6px] font-semibold">
      <div>
        <h4 className="text-4xl leading-[50.4px] font-extrabold font-raleway">
          Completed
        </h4>
      </div>
      <Card text="View Submission" winner />
      <div className="flex flex-col gap-6">
        <p className="flex flex-col pb-4  border-b border-[#E9EBEE]">
          <span className="text-sm leading-[19.07px]">Duration</span>
          <span className="text-2xl font-raleway  leading-[33.6px] font-extrabold">
            7 days
          </span>
        </p>
        <p className="flex flex-col pb-4 border-b border-[#E9EBEE]">
          <span className="text-sm leading-[19.07px]">No. of participants</span>
          <span className="text-2xl font-raleway  leading-[33.6px] font-extrabold">
            22
          </span>
        </p>
        <p className="flex flex-col pb-4 border-b border-[#E9EBEE]">
          <span className="text-sm leading-[19.07px]">Participants Judged</span>
          <span className="text-2xl font-raleway  leading-[33.6px] font-extrabold">
            18
          </span>
        </p>
        <div className="h-[46px] items-center px-4 overflow-hidden transition delay-[45] duration-500 ease-in-out bg-transparentGray rounded-[11px] flex justify-between">
          <span>Fee</span>
          <span>NGN 1,000</span>
        </div>
      </div>
      <div className="mt-auto" onClick={() => setViewSubmission(SUBMISSION)}>
        <Button type="submit" text="View your submission" />
      </div>
    </div>
  );
};

export default CompletedCard;
