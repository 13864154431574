import React from "react";

interface Props {
  name: string;
  file: null | File;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
  setPDFError: React.Dispatch<React.SetStateAction<boolean>>;
  error: boolean;
  refs: React.RefObject<HTMLInputElement>;
}
const FileInput: React.FC<Props> = ({
  name,
  file,
  setFile,
  error,
  refs,
  setPDFError,
}) => {
  const handleInputClick = () => {
    refs?.current?.click();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line no-useless-escape
    const pdf = /[\/.]pdf$/i;

    const files = e.target.files;
    if (files) {
      if (pdf.test(files[0]?.type)) {
        setFile(files && files[0]);
        setPDFError(false);
      } else {
        setPDFError(true);
      }
    }
  };
  return (
    <>
      <div
        className={`
        ${Boolean(!file) && error ? "border-danger" : "border-primary"} 
        py-3 box-border px-4 items-center flex justify-between font-quicksand font-medium text-sm leading-[17.5px]
         border rounded-curve`}
        onClick={handleInputClick}
      >
        <span className="text-gray">
          {file ? file.name : "Upload document 1"}
        </span>
        <button
          className="leading-[18px] rounded-curve border border-[#E9EBEE] py-1 px-2"
          type="button"
        >
          Select file
        </button>
      </div>
      <input
        onChange={handleFileChange}
        ref={refs}
        className="hidden"
        accept="pdf/*"
        type="file"
        value=""
        name=""
      />
    </>
  );
};

export default FileInput;
