import React from "react";
import Home from "../pages/Home";
import Login from "../pages/Login";
import HostSignUp from "../pages/HostSignUp";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsAndConditions from "../pages/TermsAndConditions";

import { createHashRouter } from "react-router-dom";
import SignUp from "../pages/SignUp";
import Layout from "../components/pageComponents/layout";
import ProfilePage from "../components/pageComponents/profile/ProfilePage";
import ProfileDashboard from "../components/pageComponents/profile/ProfileDashboard";
import JudgeDashboard from "../components/pageComponents/profile/JudgeDashboard";
import JudgeTab from "../components/reusable/tabs/JudgeTab";
import Settings from "../components/pageComponents/profile/Settings";

export const router = createHashRouter([
  {
    path: "/",
    element: <Home />,
    children: [
      {
        children: [
          {
            index: true,
            element: <Layout />,
          },
          {
            path: "/login",
            element: <Login />,
          },
          {
            path: "/signup",
            element: <SignUp />,
          },
          {
            path: "/host_signup",
            element: <HostSignUp />,
          },
          {
            path: "/privacy-policy",
            element: <PrivacyPolicy />,
          },
          {
            path: "/terms-and-conditions",
            element: <TermsAndConditions />,
          },
        ],
      },
      {
        path: "/explore",
        element: <ProfilePage host={false} user={true} />,
        children: [
          {
            index: true,
            element: <ProfileDashboard />,
          },
          {
            path: "dashboard",
            element: <ProfileDashboard />,
          },
          {
            path: "settings",
            element: <Settings />,
          },
          {
            path: "judge_section",
            element: <JudgeDashboard />,
          },
        ],
      },
      {
        path: "/contact_us",
        element: (
          <div className="pt-[90px]">
            <JudgeTab />
          </div>
        ),
      },
    ],
  },
]);
