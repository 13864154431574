import React from "react";

type Props = {
  icon?: string;
  title: string;
  text: string;
};
const HeroCards: React.FC<Props> = ({ icon, title, text }: Props) => {
  return (
    <div
      className={`${
        icon
          ? "px-8 py-11 max-w-[364px]"
          : "p-4 md:px-6 md:py-8 max-w-[240px] md:max-w-[364px]"
      } flex items-center w-fit  text-primary justify-center border border-dashed border-spacing-y-px shadow-dash rounded-[22px] border-secondary bg-white`}
    >
      <div className={`${icon ? "gap-2" : "gap-3 md:gap-6"} flex flex-col`}>
        <div className="flex flex-col gap-6">
          {icon && (
            <div className="w-fit h-fit">
              <img className="object-cover" src={icon} alt={title} />
            </div>
          )}
          <h4
            className={`${
              icon
                ? "text-[28px]"
                : "text-lg md:text-[28px] text-center md:text-left"
            } font-raleway font-extrabold  leading-[140%]`}
          >
            {icon ? `${title}` : `${title}:`}
          </h4>
        </div>
        <p
          className={`${
            icon
              ? " font-open_sans text-base leading-[180%]"
              : "font-raleway text-sm text-center md:text-left md:text-xl leading-[140%]"
          } font-semibold `}
        >
          {text}
        </p>
      </div>
    </div>
  );
};

export default HeroCards;
