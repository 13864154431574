type partners = { logo: string; partner_name: string };
interface offers {
  icon?: string;
  text: string;
  title: string;
}
export const PARTNERSLOGO: partners[] = [
  { logo: "/assets/images/partner1.svg", partner_name: "aspen oline" },
  { logo: "/assets/images/partner2.svg", partner_name: "crop and highlight" },
  { logo: "/assets/images/partner3.svg", partner_name: "N" },
  { logo: "/assets/images/partner4.svg", partner_name: "miiissy" },
  { logo: "/assets/images/partner5.svg", partner_name: "peppermint" },
  { logo: "/assets/images/partner6.svg", partner_name: "pixie labs" },
];

export const HOSTOFFERS: offers[] = [
  {
    title: "Expand your business",
    text: "Tap into a thriving community of buyers eager to discover new products and services. Promote your offerings and grow your brand with ease.",
  },
  {
    title: "Seamless wallet integration",
    text: "Automatically get a wallet for your earnings. Withdraw your funds whenever you want, and track your transactions securely.",
  },
  {
    title: "Create and sell with ease",
    text: "List your products or services with our user-friendly interface. Set prices, descriptions, and availability, and start making sales immediately.",
  },
  {
    title: "Customizable storefront",
    text: "Personalize your store to reflect your brand. Engage customers with a professional and attractive presence tailored to your style.",
  },
];

export const LOREMOFFERS: offers[] = [
  {
    icon: "/assets/icons/register.svg",
    text: "Say goodbye to limited options! Dive into a world of diverse products and services curated just for you. Whether you’re shopping for essentials or looking for something unique, it’s all here at your fingertips.",
    title: "Endless\nChoices",
  },
  {
    icon: "/assets/icons/submit.svg",
    text: "Shopping made easy! With our user-friendly platform, browse, compare, and purchase effortlessly. Say hello to a smooth shopping journey with secure payment options and quick deliveries.",
    title: "Seamless\nExperience",
  },
  {
    icon: "/assets/icons/win.svg",
    text: "Need more details or want to negotiate? Chat directly with sellers to personalize your experience and ensure you get exactly what you need. Building trust has never been easier.",
    title: "Connect with\nMerchants",
  },
];

// social links
interface socials {
  link: string;
  logo: string;
  name: string;
}

export const SOCIALICONS: socials[] = [
  {
    link: "#",
    logo: "/assets/icons/facebook.png",
    name: "facebook",
  },
  {
    link: "#",
    logo: "/assets/icons/twitter.png",
    name: "twitter",
  },
  {
    link: "#",
    logo: "/assets/icons/linkedin.png",
    name: "linkedin",
  },
  {
    link: "#",
    logo: "/assets/icons/instagram.png",
    name: "instagram",
  },
];

// footer links
export interface footerLinks {
  link?: string;
  icon?: string;
  text: string;
}
type eachLink = {
  [key: string]: footerLinks[];
};

export const ALLFOOTERLINKS: eachLink = {
  legal: [
    {
      link: "/privacy-policy",
      text: "Privacy Policy",
    },
    {
      link: "/terms-and-conditions",
      text: "Terms & Conditions",
    },
  ],
  company: [
    {
      link: "/about",
      text: "About Us",
    },
    {
      link: "/contact",
      text: "Contact Us",
    },
    {
      link: "/careers",
      text: "Careers",
    },
  ],
  contact: [
    {
      icon: "/assets/icons/call.svg",
      text: "(406) 555-0120",
    },
    {
      icon: "/assets/icons/mail.svg",
      text: "hello@bizbriger.io",
    },
    {
      icon: "/assets/icons/location.svg",
      text: "No. 4, Powa shopping mall, egbu road",
    },
  ],
};
