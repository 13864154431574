import React from "react";

const LogoutButton: React.FC = () => {
  return (
    <div className="flex py-3 px-4 rounded-[11px] border border-[#FCE8E8]">
      <button className="flex flex-1 justify-between items-center logout-button">
        Logout
      </button>
    </div>
  );
};

export default LogoutButton;
