import React, { useState } from "react";
import NavItems from "./NavItems";
import useScreenSize from "../../../hooks/useScreenSize";

const RightNavigation: React.FC = () => {
  const [navOpen, setNavOpen] = useState<boolean>(false);
  const { screenWidth } = useScreenSize();
  const mobile = screenWidth <= 854;

  const handleNavOpen = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      setNavOpen(!navOpen);
    }
  };
  return (
    <>
      {!mobile ? (
        <>
          <div className="w-fit">
            <NavItems mobile={mobile} />{" "}
            {/*should come from auth */}
          </div>
          <div className="flex gap-4" />
        </>
      ) : (
        <div
          onClick={(e) => handleNavOpen(e)}
          className={`${
            navOpen ? "h-[18px] w-[18px]" : "h-[22px] w-[22px]"
          } bg-transparent bg-no-repeat bg-center`}
          style={{
            backgroundImage: `url(${
              navOpen ? "/assets/icons/x.png" : "/assets/icons/openMenu.png"
            })`,
          }}
        >
          <div
            onClick={(e) => handleNavOpen(e)}
            className={`${
              navOpen ? " translate-x-0" : " translate-x-full"
            } fixed transition delay-[45] ease-in-out duration-200 right-0 left-0 bottom-0 bg-transparentDark backdrop-blur-[1px] top-[90px] `}
          >
            <div className="fixed right-0 max-w-[280px] w-full bg-white container-screen flex flex-col gap-6 p-6">
              <div className={'w-full'}>
                <NavItems mobile={mobile} />{" "}
                {/*should come from auth */}
              </div>
              <div className="flex flex-col gap-6" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RightNavigation;
