import React from "react";
import {
  hostPost,
  handleDate,
  handleLimitCut,
} from "../competition_card/constant";

interface Props {
  post: hostPost;
  setOpenCard: React.Dispatch<React.SetStateAction<undefined | hostPost>>;
}

const InformationCard: React.FC<Props> = ({ post, setOpenCard }: Props) => {
  return (
    <div
      className="max-w-[310px] cursor-pointer max-h-[216px] min-h-[216px] font-semibold font-open_sans text-sm leading-[22.4px] border-[3px] border-transparent hover:border-light_orange shadow-dash h-full w-full py-[22px] px-4 flex flex-col  rounded-[24px] bg-white"
      onClick={() => setOpenCard(post)}
    >
      <p className="">{handleLimitCut(post.description, 100)}</p>
      <p className="font-normal self-center mt-auto flex items-center gap-3">
        <span>{handleDate(post.datetime)}</span>
        <span className="w-1 h-1 rounded-full bg-blue"></span>
        <span>
          {post.attendees} {post.attendees > 1 ? "participants" : "participant"}
        </span>
      </p>
    </div>
  );
};

export default InformationCard;
